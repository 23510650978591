import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footer_home from "../assets/Footer_Home@2x.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import SingleContentComponentWithBulletList from "../components/SingleContentComponentWithBulletList";
import vehicleParts from "../assets/vehicleParts.png";
import simpliFiedd from "../assets/simpliFiedd.png";
import adjustStocksPart from "../assets/adjustStocksPart.png";
import streamPayrollICON from "../assets/streamPayrollICON.png";
import payrollSettingsBus from "../assets/payrollSettingsBus.png";
import dataDriverIcon from "../assets/dataDriverIcon.png";
import optimizeInvent from "../assets/optimizeInvent.png";
import eStoreVechiles from "../assets/eStoreVechiles.png";
import topItemsVehicleParts from "../assets/topItemsVehicleParts.png";
import templateSewttings from "../assets/templateSewttings.png";
import phoneTouchIcon from "../assets/phoneTouchIcon.png";
import shakeHands from "../assets/shakeHands.png";
import cust from "../assets/cust.png";
import LearnHowToUseDizlogComponent from "../components/LearnHowToUseDizlogComponent";
import danielRamos from "../assets/danielRamos.png";
import leilaniReyes from "../assets/leilaniReyes.png";
import TestimonialsRowPositionComponent from "../components/TestimonialsRowPositionComponent";
import CTAButtons from "../components/CTAButtons";

function VehiclesSuppliesParts() {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  let SubContents = [
    {
      isImageRight: false,
      backgroundColor: "#FFFFFF",
      minHeight: "478px",
      image: {
        src: adjustStocksPart,
        height: "318px",
      },
      title: t("Efficient Inventory Management with Dizlog POS"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: optimizeInvent,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Real-time Parts Tracking"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog to track vehicle parts and supplies in real-time, ensuring availability for repairs and maintenance services."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Low Inventory Alerts"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog automatically notifies you when parts run low, facilitating timely restocking and preventing delays in servicing customers."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Streamlined Reordering"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Easily reorder frequently used parts through Dizlog , ensuring a seamless flow of essential components for vehicle repairs."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#004AAD",
      minHeight: "540.82px",
      image: {
        src: eStoreVechiles,
        height: "420px",
      },
      title: t("Seamless Online Store Integration"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: phoneTouchIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Comprehensive Online Catalog"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Create an extensive online catalog of vehicle parts and supplies using Dizlog POS, allowing customers to explore and order products conveniently"
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Online Parts Ordering"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Process online parts orders efficiently through Dizlog POS, offering customers a user-friendly platform to purchase essential components."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Unified Inventory Management"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Maintain synchronization between your physical store and online store inventory with Dizlog POS, preventing overstocking or overselling."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "447px",
      image: {
        src: templateSewttings,
        height: "231.53px",
      },
      title: t("Simplified Invoicing and Payments"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: simpliFiedd,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Invoicing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Generate accurate invoices effortlessly using Dizlog, reflecting detailed part information and service charges for smooth transactions."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Flexible Payment Methods"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Accept diverse payment methods through Dizlog , including credit cards and electronic payments, enhancing customer convenience."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Tracking Payment Status"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Monitor payment status and outstanding invoices within Dizlog , ensuring prompt follow-up and minimizing payment delays."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "475px",
      image: {
        src: payrollSettingsBus,
        height: "270px",
      },
      title: t("Efficient Payroll and Employee Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: streamPayrollICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Staff Scheduling and Shifts"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog for employee scheduling, ensuring adequate staffing during peak service hours and optimizing workforce management."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Accurate Timekeeping"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Record employee work hours and attendance accurately using Dizlog, facilitating precise payroll processing."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Performance Insights"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Analyze employee performance metrics within Dizlog, enabling informed decisions on training, incentives, and improvement."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "#004AAD",
      minHeight: "483.38px",
      image: {
        src: topItemsVehicleParts,
        height: "323.38px",
      },
      title: t("Data-driven Insights and Decision-making"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: dataDriverIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Sales Analytics and Trends"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog analytics to identify popular vehicle parts and supplies, informing inventory management and pricing strategies."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Inventory Reports"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Generate comprehensive inventory reports with Dizlog , aiding in optimizing stock levels and making informed purchasing decisions."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Employee Performance Analysis"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Leverage Dizlog data to assess employee efficiency, sales contribution, and customer engagement, facilitating effective workforce management."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#FEF7EC",
      minHeight: "416.95px",
      image: {
        src: cust,
        height: "256.95px",
      },
      title: t("Enhanced Customer Experience and Engagement"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: shakeHands,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Personalized Customer Interactions"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Leverage customer data within Dizlog to tailor price list and payment terms"
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Loyalty Programs and Offers"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Implement loyalty programs through Dizlog to reward frequent customers and incentivize repeat purchases of your artistic products."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
  ];

  let testimonials = [
    {
      content: t(
        "“As the owner of a Vehicles Supplies and Parts Shop, I am thrilled to share how DizLog POS has revolutionized our business. This system has become an essential tool in our daily operations, and I can confidently say it has exceeded all our expectations. The inventory management feature has been a game-changer for our shop.”"
      ),
      name: t("Leilani Reyes"),
      designation: t("Vehicles Supplies Shop Owner"),
      imageSrc: leilaniReyes,
    },
    {
      content: t(
        "“The sales analytics provided by DizLog POS have been invaluable. We can now identify our best-selling products, track sales trends, and understand customer preferences. This data-driven approach has allowed us to optimize our product selection, adjust our marketing strategies, and stay ahead in the competitive market.”"
      ),
      name: t("Daniel Ramos"),
      designation: t("Vehicles Supplies Shop Owner"),
      imageSrc: danielRamos,
    },
  ];
  return (
    <>
      <Header i18n={i18n} screen="business-types" />
      <div id="businessRetail">
        <section>
          <div className="min-h-[366px] bg-[#FEF7EC] flex flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row">
            <div className="flex flex-1 items-end   justify-center">
              <img src={vehicleParts} className="h-[337.44px] w-auto" />
            </div>
            <div className="flex flex-1 justify-center items-center py-4">
              <div className="flex flex-col w-[80%]">
                <span
                  className="font-[Raleway] font-[700] 
                  text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px] 
                  leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px] text-[#00215D]"
                >
                  {t("DizLog for Vehicles Supplies and Parts Shop")}
                </span>
                <span
                  className="mt-2 font-[Roboto] font-[400] 
                  text-[14px] leading-[19px] text-[#6B6B6B]"
                >
                  {t(
                    "DizLog POS is a valuable asset for Vehicles Supplies and Parts Shops, offering a comprehensive solution that can significantly enhance their business operations and customer service. The user-friendly interface allows shop owners and employees to efficiently manage sales, process transactions, and track inventory with ease. The option to use tablets or smartphones for order processing streamlines the checkout process, ensuring quick and accurate transactions for customers looking to purchase vehicle parts and supplies."
                  )}
                </span>
                <div className="mt-8">
                  <CTAButtons requestToDemoPath="/lifetime-deals/retail" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {SubContents.map((item) => (
          <SingleContentComponentWithBulletList data={item} />
        ))}
      </div>
      <LearnHowToUseDizlogComponent />
      <TestimonialsRowPositionComponent testimonials={testimonials} />
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default VehiclesSuppliesParts;
