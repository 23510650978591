import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footer_home from "../assets/Footer_Home@2x.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import SingleContentComponentWithBulletList from "../components/SingleContentComponentWithBulletList";
import coffeeShopp from "../assets/coffeeShopp.png";
import streamPayrollICON from "../assets/streamPayrollICON.png";
import optimizeInvent from "../assets/optimizeInvent.png";
import kdsSCreen from "../assets/kdsSCreen.png";
import phoneTouchIcon from "../assets/phoneTouchIcon.png";
import orderMgmtFastfood from "../assets/orderMgmtFastfood.png";
import timeTracking from "../assets/timeTracking.png";
import arrowUP from "../assets/arrowUP.png";
import adjustStocksCoffee from "../assets/adjustStocksCoffee.png";
import kitchenIcon from "../assets/kitchenIcon.png";
import orderMgmt from "../assets/orderMgmt.png";
import tableIcon from "../assets/tableIcon.png";
import salesOverviewCofffee from "../assets/salesOverviewCofffee.png";
import orderCoffee from "../assets/orderCoffee.png";
import LearnHowToUseDizlogComponent from "../components/LearnHowToUseDizlogComponent";
import pauleneVitancol from "../assets/pauleneVitancol.png";
import judeCadena from "../assets/judeCadena.png";
import TestimonialsRowPositionComponent from "../components/TestimonialsRowPositionComponent";
import CTAButtons from "../components/CTAButtons";

function CafeCoffeeShop() {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  let SubContents = [
    {
      isImageRight: false,
      backgroundColor: "#FFFFFF",
      minHeight: "450px",
      image: {
        src: orderMgmtFastfood,
        height: "284px",
      },
      title: t("Streamline Your Ordering Processes"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: phoneTouchIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Streamlined Order Processing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Simplify the order processing workflow with Dizlog POS. From capturing customer orders to sending them to the kitchen or barista, the system optimizes the order fulfillment process, reducing errors and minimizing wait times."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Order History and Reporting"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Access comprehensive order history and generate detailed reports to gain valuable insights into your sales performance. Dizlog POS provides analytics and reporting tools that allow you to track order trends, identify popular menu items, and make data-driven decisions to drive profitability."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#004AAD",
      minHeight: "554px",
      image: {
        src: salesOverviewCofffee,
        height: "304.31px",
      },
      title: t("Customize and Structure Your Sales Process"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: arrowUP,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Enhanced Menu Customization"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Tailor your menu to meet Your specific requirements and organize items by Categories."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Effortless Item Customization"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Easily add toppings, modifiers, and add-ons to enhance customer choices and Preferences."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Versatile Dining Options"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Create flexible dining options such as dine-In, takeout, and delivery to cater to varied customer needs."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Seamless Discount and Note Management"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Apply discounts, taxes, and add notes to items or orders for accurate and efficient sales processing."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "412.69px",
      image: {
        src: orderMgmt,
        height: "252.69px",
      },
      title: t("Table Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: tableIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Efficient table assignments and order routing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog POS optimizes table assignments and directs orders to the appropriate kitchen stations, reducing confusion and delays."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Improves overall dining experience and service speed"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "With smooth table management, cafes can provide better service, leading to improved customer satisfaction and repeat business."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "447px",
      image: {
        src: timeTracking,
        height: "269px",
      },
      title: t("Employee Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: streamPayrollICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Tracks employee shifts and performance"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog POS helps cafes manage employee schedules and track their performance, ensuring proper staffing and accountability."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Assists in scheduling and payroll management"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "The system aids in scheduling shifts and simplifies payroll processing, saving time and reducing administrative burdens."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Provides insights for staff optimization and training"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "With performance data, cafes can identify areas for staff improvement, enhancing overall operational efficiency and customer service."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "##004AAD",
      minHeight: "447px",
      image: {
        src: orderCoffee,
        height: "273.41px",
      },
      title: t("Integration with Online Orders"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: phoneTouchIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Seamlessly integrates with online ordering"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog POS seamlessly connects with your very own online store, ensuring a smooth flow of orders from online store to the cafe's kitchen."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Ensures accurate order processing for pickup or delivery"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "The integration eliminates manual errors and helps cafes fulfill online orders accurately and efficiently."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Reduces errors and enhances customer satisfaction"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "With automated online order processing, cafes can minimize mistakes, providing a positive experience for online customers."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#FEF7EC",
      minHeight: "475px",
      image: {
        src: kdsSCreen,
        height: "271px",
      },
      title: t("Reduced Wait Times With Kitchen Printers/Display"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: kitchenIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Connect Kitchen Printers or Kitchen Displays"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog POS accelerates the order flow, enabling the kitchen staff to prepare orders promptly and reduce overall wait times."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Shortens customer wait times for orders"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Faster order processing and kitchen communication result in reduced waiting times for customers, leading to higher satisfaction levels."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        // {
        //   icon: orangeCircleCheckWhite,
        //   title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        //   titleClassNames:
        //     "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
        //   description:
        //     "Faster order processing and kitchen communication result in reduced waiting times for customers, leading to higher satisfaction levels.",
        //   descriptionClassNames:
        //     "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        // },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "#004AAD",
      minHeight: "508px",
      image: {
        src: adjustStocksCoffee,
        height: "318px",
      },
      title: t("Manage your Inventory"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: optimizeInvent,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Purchase Orders"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Streamline stock replenishment with quick and easy purchase orders for fresh products."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Ingredient Management"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Effectively manage stock of produced items and ingredients using composite item production."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Multi-Location Support"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Seamlessly transfer goods between your different locations."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: "Stock Management",
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Stock Maintain accurate stock levels through regular counting and stock correction"
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
  ];
  let testimonials = [
    {
      content: t(
        "“Ever since we implemented DizLog POS in our coffee shop, our daily operations have been nothing short of amazing. This system has truly been a game-changer, and I can't imagine running our coffee shop without it. DizLog POS has significantly improved our order management process.”"
      ),
      name: t("Paulene Vitancol"),
      designation: t("Coffee Shop Owner"),
      imageSrc: pauleneVitancol,
    },
    {
      content: t(
        "“The sales analytics provided by DizLog POS have been incredibly valuable. We can access real-time data on our cafe's performance, including popular menu items, peak hours, and customer preferences. This insight has enabled us to make data-driven decisions, adjust our offerings, and run targeted promotions, all of which have had a positive impact on our revenue.”"
      ),
      name: t("Jude Cadena"),
      designation: t("Coffee Shop Owner"),
      imageSrc: judeCadena,
    },
  ];
  return (
    <>
      <Header i18n={i18n} screen="business-types" />
      <div id="businessRetail">
        <section>
          <div
            className="min-h-[366px] bg-[#FEF7EC] flex
          flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row "
          >
            <div className="flex flex-1 items-end justify-center pt-4 px-4 sm:px-0">
              <img src={coffeeShopp} className="h-[90%] max-h-[500px] w-auto" />
            </div>
            <div className="flex flex-1 justify-center items-center py-4">
              <div className="flex flex-col w-[80%]">
                <span
                  className="font-[Raleway] font-[700] 
              text-[#00215D]
             text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px] 
             leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px]"
                >
                  {t("DizLog for Cafe and Coffee Shops")}
                </span>
                <span
                  className="mt-2 font-[Roboto] font-[400] 
                  text-[14px] leading-[19px] text-[#6B6B6B]"
                >
                  {t(
                    "Taking orders via tablets or smartphones enables baristas to swiftly and accurately serve customers, reducing wait times and ensuring order precision. The system's inventory management feature allows cafe owners to monitor coffee bean and supply levels in real-time, ensuring they never run out of customer favorites. This optimization of inventory leads to cost savings and better stock control. DizLog POS's employee management tools streamline staff scheduling, performance tracking, and task assignments, leading to a more organized and productive team."
                  )}
                </span>
                <div className="mt-8">
                  <CTAButtons requestToDemoPath="/lifetime-deals/restaurant" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {SubContents.map((item) => (
          <SingleContentComponentWithBulletList data={item} />
        ))}
      </div>
      <LearnHowToUseDizlogComponent />
      <TestimonialsRowPositionComponent testimonials={testimonials} />
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default CafeCoffeeShop;
