import React from 'react'
import NavigationButtons from '../NavigationButtons'
import ArrowRightOrange from '../../assets/arrowRightOrangeFull.png'
import { Link } from 'react-router-dom'

const ThreeBigCards = ({hasNav, data}) => {
  return (
    <section className='bg-[#F6F6FF] flex flex-col items-center py-8 px-4'>
      <div className='flex flex-col max-w-4xl'>
        {hasNav && (<NavigationButtons className={"mt-0"} />)}
        {data.title &&
        <div className='text-[#00215D] font-bold text-3xl w-full lg:w-1/2 font-[Raleway] mb-4'>
          {data.title}
        </div>}
        {data.description &&
        <Link className='text-[#F09000] font-bold text-xs mb-4 font-[Roboto] no-underline' to={data.descriptionLink}>
          {data.description} <img src={ArrowRightOrange} alt='arrow-right-orange' className='w-3 h-2 inline' />
        </Link>}
        <div className='d-flex flex-col md:flex-row gap-4'>
          {data.items.map((item, index) => (
            <div 
              key={index}
              className='flex flex-col gap-2 w-full md:w-1/3'
            >
              <div className='relative w-full'>
                <img 
                  src={item.image} 
                  alt="various-platforms" 
                  className='w-full h-auto rounded-lg'
                />
                {item.link &&
                <Link 
                  className='w-10 h-10 rounded-full bg-transparent border-2 border-[#F09000] absolute bottom-0 right-0 flex items-center justify-center m-2 hover:bg-white'
                  to={item.link}
                >
                  <img src={ArrowRightOrange} alt='arrow-right-orange' className='w-4 h-3 inline' />
                </Link>}
              </div>
              <p className='px-2'>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ThreeBigCards