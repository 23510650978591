import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footer_home from "../assets/Footer_Home@2x.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import SingleContentComponentWithBulletList from "../components/SingleContentComponentWithBulletList";
import dizLogBars from "../assets/dizLogBars.png";
import streamPayrollICON from "../assets/streamPayrollICON.png";
import optimizeInvent from "../assets/optimizeInvent.png";
import cust from "../assets/cust.png";
import manageOrderBrew from "../assets/manageOrderBrew.png";
import phoneTouchIcon from "../assets/phoneTouchIcon.png";
import shakeHands from "../assets/shakeHands.png";
import orderMgmt from "../assets/orderMgmt.png";
import tableIcon from "../assets/tableIcon.png";
import adjustStocksBrew from "../assets/adjustStocksBrew.png";
import empHRMS from "../assets/empHRMS.png";
import LearnHowToUseDizlogComponent from "../components/LearnHowToUseDizlogComponent";
import aileenRosel from "../assets/aileenRosel.png";
import paulManzano from "../assets/paulManzano.png";
import TestimonialsRowPositionComponent from "../components/TestimonialsRowPositionComponent";
import CTAButtons from "../components/CTAButtons";

function BarsAndBreweries() {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  let SubContents = [
    {
      isImageRight: false,
      backgroundColor: "#FFFFFF",
      minHeight: "447px",
      image: {
        src: manageOrderBrew,
        height: "275.71px",
      },
      title: t("Order Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: phoneTouchIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Efficient Drink Ordering"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Streamline the drink order process, minimizing wait times and ensuring swift service for a seamless customer experience."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Accurate Order Handling"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Minimize errors in drink orders, guaranteeing precise billing and enhancing overall customer satisfaction."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Add ons and Variants availability"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Easily manage complex drink orders, modifications, and special requests to accommodate individual customer preferences effectively."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#004AAD",
      minHeight: "478px",
      image: {
        src: adjustStocksBrew,
        height: "318px",
      },
      title: t("Advance Inventory Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: optimizeInvent,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Real-time Drink Stock Tracking"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Monitor alcohol and beverage stock levels in real-time, preventing shortages during peak hours and maintaining consistent supply."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Low Inventory Alerts"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Receive automated notifications for low drink inventory, enabling timely restocking and uninterrupted service to customers."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Efficient Inventory Utilization"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Optimize inventory levels, minimizing wastage and overstocking of beverages to reduce costs and improve profitability."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "416.95px",
      image: {
        src: cust,
        height: "256.95px",
      },
      title: t("Customer Relationship Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: shakeHands,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Loyalty Program Implementation"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Establish loyalty programs and special offers to enhance customer retention and foster a loyal customer base."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Build a Dedicated Customer Base"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Cultivate a Pool of Loyal Visitors Who Regularly Choose Your Restaurant for Their Dining Needs."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "413.32px",
      image: {
        src: orderMgmt,
        height: "269px",
      },
      title: t("Table Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: tableIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Efficient table assignments and order routing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog POS optimizes table assignments and directs orders to the appropriate kitchen stations, reducing confusion and delays."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Improves overall dining experience and service speed"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "With smooth table management, cafes can provide better service, leading to improved customer satisfaction and repeat business."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "##004AAD",
      minHeight: "508px",
      image: {
        src: empHRMS,
        height: "272.94px",
      },
      title: t("Efficient Employee Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: streamPayrollICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Track Sales performance"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Gain insights into individual sales performance to make data-driven decisions"
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Secure Access"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Maintain data security by managing employee access to sensitive information and functions."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Time Management"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Streamline time tracking with clock in/out functionality and automated total hours calculation."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Enhanced Cashier Register"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Ensure accountability by giving each employee own cash register with PIN code for additional security."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
  ];

  let testimonials = [
    {
      content: t(
        "“The sales analytics provided by DizLog POS have been incredibly valuable in understanding our business performance. We can identify our top-selling drinks, track sales trends, and even analyze customer preferences. Armed with this data, we've been able to make data-driven decisions to tailor our offerings to what our customers love most.”"
      ),
      name: t("Aileen Rosel"),
      designation: t("Bar Owner"),
      imageSrc: aileenRosel,
    },
    {
      content: t(
        "“DizLog POS has been a game-changer for our bars and breweries. Its efficiency in order management, inventory control, employee management, sales analytics, and customer loyalty program has elevated our business to new heights. I highly recommend DizLog POS to any bar or brewery owner looking to enhance their operations and provide an exceptional experience to their customers.”"
      ),
      name: t("Paul Manzano"),
      designation: t("Bar Owner"),
      imageSrc: paulManzano,
    },
  ];
  return (
    <>
      <Header i18n={i18n} screen="business-types" />
      <div id="businessRetail">
        <section>
          <div
            className="min-h-[366px] bg-[#FEF7EC] flex
              flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row "
          >
            <div className="flex flex-1 items-end justify-center pt-4 px-4 sm:px-0">
              <img src={dizLogBars} className="h-[90%] max-h-[500px] w-auto" />
            </div>
            <div className="flex flex-1 justify-center items-center py-4">
              <div className="flex flex-col w-[80%]">
                <span
                  className="font-[Raleway] font-[700] 
                  text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px] 
                  leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px] text-[#00215D]"
                >
                  {t("DizLog for Bars and Breweries")}
                </span>
                <span
                  className="mt-2 font-[Roboto] font-[400] 
                  text-[14px] leading-[19px] text-[#6B6B6B]"
                >
                  {t(
                    "With its user-friendly interface, the system enables bartenders to efficiently take orders using tablets or smartphones, ensuring prompt service and accurate drink orders. This streamlined order management process minimizes wait times for customers, leading to increased satisfaction and loyalty. The inventory management feature allows Bars and Breweries to monitor liquor and beer stock levels in real-time, preventing shortages and enabling better stock control. This not only ensures a wide variety of beverages are always available but also helps in managing inventory costs effectively."
                  )}
                </span>
                <div className="mt-8">
                  <CTAButtons requestToDemoPath="/lifetime-deals/restaurant" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {SubContents.map((item) => (
          <SingleContentComponentWithBulletList data={item} />
        ))}
      </div>
      <LearnHowToUseDizlogComponent />
      <TestimonialsRowPositionComponent testimonials={testimonials} />
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default BarsAndBreweries;
