import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footer_home from "../assets/Footer_Home@2x.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import SingleContentComponentWithBulletList from "../components/SingleContentComponentWithBulletList";
import artsCraft from "../assets/artsCraft.png";
import simpliFiedd from "../assets/simpliFiedd.png";
import adjustStockArt from "../assets/adjustStockArt.png";
import streamPayrollICON from "../assets/streamPayrollICON.png";
import payrollSettingsBus from "../assets/payrollSettingsBus.png";
import dataDriverIcon from "../assets/dataDriverIcon.png";
import optimizeInvent from "../assets/optimizeInvent.png";
import checkoutArts from "../assets/checkoutArts.png";
import topItemArts from "../assets/topItemArts.png";
import newInvoiceArts from "../assets/newInvoiceArts.png";
import phoneTouchIcon from "../assets/phoneTouchIcon.png";
import shakeHands from "../assets/shakeHands.png";
import cust from "../assets/cust.png";
import LearnHowToUseDizlogComponent from "../components/LearnHowToUseDizlogComponent";
import mateoGarcia from "../assets/mateoGarcia.png";
import mayaDeleCruz from "../assets/mayaDeleCruz.png";
import TestimonialsRowPositionComponent from "../components/TestimonialsRowPositionComponent";
import CTAButtons from "../components/CTAButtons";

function ArtsCraftsNovelty() {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  let SubContents = [
    {
      isImageRight: false,
      backgroundColor: "#FFFFFF",
      minHeight: "478px",
      image: {
        src: adjustStockArt,
        height: "318px",
      },
      title: t("Efficient Inventory Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: optimizeInvent,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Real-time Inventory Tracking"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog to monitor art and craft supplies in real-time, preventing stockouts and ensuring items are available for creative projects."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Low Inventory Alerts"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog automatically generates alerts when supplies run low, enabling timely restocking to avoid disruptions in crafting activities."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Streamlined Reordering"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Easily reorder popular materials and products directly through Dizlog , ensuring a continuous supply of essential items for your creative endeavors."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#004AAD",
      minHeight: "540.82px",
      image: {
        src: checkoutArts,
        height: "380.82px",
      },
      title: t("Seamless Online Store Integration"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: phoneTouchIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Comprehensive Online Catalog"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog to create an extensive online catalog showcasing your diverse range of art and craft products, captivating a broader audience."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Online Order Processing"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Seamlessly process online orders through Dizlog , providing customers with a convenient and secure way to purchase your unique creations."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Unified Inventory Management"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Ensure inventory synchronization between your physical store and online store, preventing overselling and maintaining accurate stock levels."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "557.92px",
      image: {
        src: newInvoiceArts,
        height: "397.92px",
      },
      title: t("Simplified Invoicing and Payments"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: simpliFiedd,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Invoicing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Generate invoices effortlessly using Dizlog, accurately reflecting purchased items, quantities, and prices for a streamlined transaction process."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Multiple Payment Options"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Offer diverse payment methods, from credit cards to digital wallets, through Dizlog, accommodating customer preferences and enhancing convenience."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Tracking Outstanding Payments"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Monitor and manage outstanding invoices within Dizlog, ensuring timely follow-up and reducing the risk of payment delays."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "493px",
      image: {
        src: payrollSettingsBus,
        height: "270px",
      },
      title: t("Efficient Payroll and Employee Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: streamPayrollICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Staff Scheduling and Shift Management"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog to schedule employee shifts, ensuring proper coverage during peak hours and optimizing staff resources."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Accurate Timekeeping"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog records employee work hours and attendance, simplifying payroll processing and minimizing discrepancies."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Performance Tracking"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Monitor employee performance metrics within Dizlog , allowing for informed decisions on training, recognition, and improvements."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "#004AAD",
      minHeight: "483.38px",
      image: {
        src: topItemArts,
        height: "323.38px",
      },
      title: t("Data-driven Insights and Decision-making"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: dataDriverIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Service Analytics and Trends"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Leverage Dizlog to analyze sales patterns and identify popular art and craft items, guiding inventory management and marketing strategies."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Inventory Reports"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Generate detailed inventory reports using Dizlog , offering insights into stock turnover rates and helping optimize ordering decisions."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Employee Performance Analysis"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize employee performance data from Dizlog to assess productivity, sales contribution, and customer engagement, enhancing workforce management."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#FEF7EC",
      minHeight: "416.95px",
      image: {
        src: cust,
        height: "256.95px",
      },
      title: t("Enhanced Customer Experience and Engagement"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: shakeHands,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Personalized Customer Interactions"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Leverage customer data within Dizlog to tailor price list and payment terms"
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Loyalty Programs and Offers"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Implement loyalty programs through Dizlog to reward frequent customers and incentivize repeat purchases of your artistic products."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
  ];

  let testimonials = [
    {
      content: t(
        "“Overall, DizLog POS has been an indispensable tool for our Arts, Crafts, and Novelty shop. Its efficiency in order management, inventory control, sales analytics, and customer loyalty program has empowered us to run our business more smoothly and provide an exceptional shopping experience for our customers.”"
      ),
      name: t("Maya dela Cruz"),
      designation: t("Arts & Cratfts Shop Owner"),
      imageSrc: mayaDeleCruz,
    },
    {
      content: t(
        "“As the owner of an Arts, Crafts, and Novelty shop, I cannot recommend DizLog POS enough. This system has been a game-changer for our business, transforming the way we manage our operations and serve our customers. DizLog POS's user-friendly interface has made a significant impact on our daily workflow.”"
      ),
      name: t("Mateo Garcia"),
      designation: t("Arts & Cratfts Shop Owner"),
      imageSrc: mateoGarcia,
    },
  ];

  return (
    <>
      <Header i18n={i18n} screen="business-types" />
      <div id="businessRetail">
        <section>
          <div className="min-h-[366px] bg-[#FEF7EC] flex   flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row">
            <div className="flex flex-1 items-end justify-center pt-4">
              <img src={artsCraft} className="h-[90%] max-h-[500px] w-auto" />
            </div>
            <div className="flex flex-1 justify-center items-center py-4">
              <div className="flex flex-col w-[80%]">
                <span
                  className="font-[Raleway] font-[700] 
                  text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px] 
                  leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px] text-[#00215D]"
                >
                  {t("DizLog for Arts, Craft and Novelty")}
                </span>
                <span
                  className="mt-2 font-[Roboto] font-[400] 
                  text-[14px] leading-[19px] text-[#6B6B6B]"
                >
                  {t(
                    "DizLog POS is a valuable tool for arts, craft, and novelty shops, offering a comprehensive solution to enhance their business operations and create a seamless customer experience. With its user-friendly interface, the system enables shop owners to efficiently manage inventory, process sales, and keep track of orders. The inventory management feature allows arts, craft, and novelty shops to monitor stock levels in real-time, ensuring that popular items are always in stock and preventing any inventory shortages."
                  )}
                </span>
                <div className="mt-8">
                  <CTAButtons requestToDemoPath="/lifetime-deals/retail" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {SubContents.map((item) => (
          <SingleContentComponentWithBulletList data={item} />
        ))}
      </div>
      <LearnHowToUseDizlogComponent />
      <TestimonialsRowPositionComponent testimonials={testimonials} />
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default ArtsCraftsNovelty;
