import React from "react";
import "./ModalComponent.css";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import closeSvg from "../assets/close.svg";
import LiveChatsupport24_7icon from "../assets/LiveChatsupport24_7icon.png";
import helpCenter from "../assets/helpCenter.png";

let sampleProps = [

    {
        content:"",
        name:"",
        designation:"",
        imageSrc:""
    }
]
function TestimonialsRowPositionComponent({ testimonials  }) {

  return (
    <>
      <section id="">
      <>
      <section id="IFPOIALK">
        <div
          className="p-4 md:p-8 bg-[#F6F6FF] min-h-[600px] w-full flex flex-col justify-center items-center gap-4"
        >
          <div className="flex justify-center items-center flex-col gap-2 pt-4">
            <span className="font-[600] font-[Roboto] text-[20px] text-[#F09000] mt-2">
              Our Testimonials
            </span>
            <span
              className="font-[Raleway] font-[700] 
            text-xl xl:text-[45px] lg:text-[45px] md:text-[45px] sm:text-[45px] text-[#00215D]"
            >
              What Our Clients Say?
            </span>
          </div>
          <div className="flex flex-col md:flex-row gap-4 py-4">
            {testimonials.map(({content, name, designation, imageSrc}) => (
              <div className="flex flex-col flex-1 justify-end items-center bg-white max-w-[565px] min-h-[300px] shadow-sm rounded-sm p-4">
                <div className="font-[Roboto] text-[15px] font-[400] text-[#6B6B6B] flex-1 leading-[25px]">{content}</div>
                <div className="flex w-full pt-3">
                  <div className="flex flex-col w-full">
                    <span
                      className="font-[Raleway] font-[700] 
                      text-lg xl:text-[24px] lg:text-[24px] md:text-[24px] sm:text-[24px] text-[#00215D]
                        "
                    >
                      {name}
                    </span>
                    <span className="font-[Roboto] text-[15px] text-[#F09000] font-[600]">
                      {designation}
                    </span>
                  </div>
                  <div className="flex justify-end w-full">
                    <img src={imageSrc} className="h-auto w-[80px] " />
                  </div>
                </div>
              </div>
            ))}
            
          </div>
        </div>
      </section>{" "}
    </>
      </section>
    </>
  );
}

export default TestimonialsRowPositionComponent;
