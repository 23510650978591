import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const CTAButtons = ({ requestToDemoPath = "/live-demo" }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onGetStarted = () => {
    window.location.assign("https://web.dizlog.com/sign-up");
  };

  const handleRequestDemo = () => {
    navigate(requestToDemoPath);
  };

  return (
    <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
      <Button
        type="primary"
        className="h-button-big text-button-big px-4"
        onClick={handleRequestDemo}
      >
        {t("Request a Demo")}
      </Button>
      <Button
        type="default"
        className="h-button-big text-button-big px-4"
        onClick={onGetStarted}
      >
        {t("Get Started")}
      </Button>
    </div>
  );
};

export default CTAButtons;
