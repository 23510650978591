import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footer_home from "../assets/Footer_Home@2x.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import homeMainTenanceService from "../assets/homeMainTenanceService.png";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import SingleContentComponentWithBulletList from "../components/SingleContentComponentWithBulletList";
import streamLinedService from "../assets/streamLinedService.png";
import airconCleaning from "../assets/airconCleaning.png";
import adjustStoksHome from "../assets/adjustStoksHome.png";
import optimizeInvent from "../assets/optimizeInvent.png";
import convenientOnlineAccess from "../assets/convenientOnlineAccess.png";
import hairCareSalon from "../assets/hairCareSalon.png";
import simplifiedInvoicee from "../assets/simplifiedInvoicee.png";
import newInv from "../assets/newInv.png";
import effectiveTEamPayroll from "../assets/effectiveTEamPayroll.png";
import payrollSettings from "../assets/payrollSettings.png";
import qualityServices from "../assets/qualityServices.png";
import topcat from "../assets/topcat.png";
import centralisedOPS from "../assets/centralisedOPS.png";
import paySet from "../assets/paySet.png";
import LearnHowToUseDizlogComponent from "../components/LearnHowToUseDizlogComponent";
import TestimonialsRowPositionComponent from "../components/TestimonialsRowPositionComponent";
import EmilyCruz from "../assets/EmilyCruz.png";
import GaelBautista from "../assets/GaelBautista.png";
import CTAButtons from "../components/CTAButtons";



function HomeMaintenaceService() {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;


  const ourTestimonials = [
    {
      content: t("“As a small business owner in the Home and Maintenance Services industry, I can't express enough how invaluable DizLog POS has been for my company. This remarkable point-of-sale system has truly transformed the way we operate and manage our services. With DizLog POS, scheduling and managing appointments has become a breeze, allowing us to efficiently allocate our resources and serve our clients promptly.”"),
      name: t("Emily Cruz"),
      designation: t("Home Maintenance Services Owner"),
      imageSrc: EmilyCruz
    },
    {
      content: t("“Being in the Home and Maintenance Services industry for over a decade, I can confidently say that DizLog POS has been a game-changer for my business. This powerful point-of-sale system has revolutionized the way we operate and manage our services. From the moment we started using DizLog POS, it was evident that our efficiency soared.”"),
      name: t("Gael Bautista"),
      designation: t("Home Maintenance Services Owner"),
      imageSrc: GaelBautista
    }
  ]

  let SubContents = [
    {
      isImageRight: false,
      backgroundColor: "#FFFFFF",
      minHeight: "650.77px",
      image: {
        src: airconCleaning,
        height: "490.77px",
      },
      title: t("Streamlined Service Booking with Dizlog"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: streamLinedService,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Effortless Appointment Scheduling"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog for seamless and easy appointment scheduling, enabling customers to book home and maintenance services with convenience."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Real-time Availability"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog provides real-time visibility into service availability, allowing customers to choose suitable time slots based on their preferences and your business's schedule."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Appointment Reminders"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Set up automated appointment reminders through Dizlog, reducing no-shows and ensuring a smooth scheduling process for both customers and your team."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#004AAD",
      minHeight: "478px",
      image: {
        src: adjustStoksHome,
        height: "318px",
      },
      title: t("Optimized Inventory and Resource Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: optimizeInvent,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Efficient Resource Tracking"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog to monitor and manage essential resources, ensuring that your home and maintenance services have the necessary tools and supplies."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Low Inventory Alerts"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Receive automated notifications for low inventory levels via Dizlog, allowing you to restock promptly and avoid service disruptions."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Resource Reports and Insights"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Generate comprehensive resource reports and insights using Dizlog, assisting in resource allocation decisions and optimizing service delivery."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "449px",
      image: {
        src: hairCareSalon,
        height: "291.45px",
      },
      title: t("Convenient Online Service Access"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: convenientOnlineAccess,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("User-friendly Online Booking"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("reate a user-friendly online store with Dizlog, enabling customers to easily browse and book your home and maintenance services at any time."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Secure Payment Processing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog ensures secure online payment processing, allowing customers to pay for services in advance and enhancing the overall service experience."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("24/7 Service Availability"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("With Dizlog, your services become available for booking around the clock, providing customers with flexibility and increasing your business's accessibility."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "449px",
      image: {
        src: newInv,
        height: "397.92px",
      },
      title: t("Simplified Invoicing and Payment Handling"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: simplifiedInvoicee,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Invoicing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog to generate automated invoices, providing customers with transparent and accurate billing for the home and maintenance services they receive."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Payment Versatility"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog supports various payment methods, allowing customers to pay for services using their preferred payment options, enhancing convenience."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Payment Tracking and Reminders"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("tilize Dizlog to track payment statuses and send automated reminders, ensuring timely payments and improving your financial management."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#004AAD ",
      minHeight: "475px",
      image: {
        src: payrollSettings,
        height: "270px",
      },
      title: t("Effective Team Management and Payroll"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: effectiveTEamPayroll,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Staff Scheduling"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog for efficient staff scheduling, ensuring your team is well-organized and equipped to provide timely home and maintenance services."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Accurate Timekeeping"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog accurately records employee work hours, simplifying payroll processing and ensuring fair compensation for your team members."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Performance Tracking and Growth"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Leverage Dizlog data to assess employee performance and identify opportunities for growth and improvement, fostering a motivated workforce."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
        isImageRight: true,
        backgroundColor: "#FEF7EC",
        minHeight: "483.38px",
        image: {
          src: topcat,
          height: "323.38px",
        },
        title: t("Data-driven Insights for Quality Services"),
        titleClassNames:
          "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
        titleImg: qualityServices,
        titleImgClassNames: "",
        titleDesc: "",
        titleDescImgClassNames: "",
        bullets: [
          {
            icon: orangeCircleCheckWhite,
            title: t("Service Analytics"),
            titleClassNames:
              "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
            description:
              t("Utilize Dizlog analytics to gain insights into service performance, customer preferences, and trends, guiding informed decisions to enhance your offerings."),
            descriptionClassNames:
              "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
          },
          {
            icon: orangeCircleCheckWhite,
            title: t("Resource Utilization Reports"),
            titleClassNames:
              "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
            description:
              t("Generate resource utilization reports using Dizlog data, enabling you to optimize resource allocation and streamline service processes."),
            descriptionClassNames:
              "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
          },
          {
            icon: orangeCircleCheckWhite,
            title: t("Financial Insights"),
            titleClassNames:
              "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
            description:
              t("Gain financial insights through Dizlog data analysis, empowering you to make strategic financial decisions for your home and maintenance business."),
            descriptionClassNames:
              "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
          },

        ],
    }, 
    {
        isImageRight: false,
        backgroundColor: "#FFFFFF",
        minHeight: "493px",
        image: {
          src: paySet,
          height: "270px",
        },
        title: t("Centralized Operations and Accessibility"),
        titleClassNames:
          "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
        titleImg: centralisedOPS,
        titleImgClassNames: "",
        titleDesc: "",
        titleDescImgClassNames: "",
        bullets: [
          {
            icon: orangeCircleCheckWhite,
            title: t("Integrated Business Hub"),
            titleClassNames:
              "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
            description:
              t("Dizlog serves as a central hub for managing appointments, resources, invoicing, payroll, and employee information, simplifying your home and maintenance business operations."),
            descriptionClassNames:
              "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
          },
          {
            icon: orangeCircleCheckWhite,
            title: t("Cloud-based Access"),
            titleClassNames:
              "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
            description:
              t("Access Dizlog remotely from anywhere with an internet connection, enabling you to manage your business on-the-go and stay connected to your operations."),
            descriptionClassNames:
              "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
          },
          {
            icon: orangeCircleCheckWhite,
            title: t("Scalable Support"),
            titleClassNames:
              "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
            description:
              t("As your home and maintenance business grows, Dizlog scales seamlessly to accommodate increased demand, ensuring continued support for your success."),
            descriptionClassNames:
              "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
          },

        ],
    },  
  ];

  return (
    <>
      <Header i18n={i18n} screen="business-types" />
      <div id="businessRetail">
        <section>
          <div className="min-h-[366px] bg-[#FEF7EC] flex flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row ">
            <div className="flex flex-1 items-end justify-center">
              <img
                src={homeMainTenanceService}
                className="h-[337.44px] w-auto"
              />
            </div>
            <div className="flex flex-1 justify-center items-center py-4">
              <div className="flex flex-col w-[80%]">
                <span
                  className="font-[Raleway] font-[700] 
                  text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px] 
                  leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px]
                   text-[#00215D]"
                >
                  {t("DizLog for Home and Maintenance Services")}
                </span>
                <span
                  className="mt-2 font-[Roboto] font-[400] 
                  text-[14px] leading-[19px] text-[#6B6B6B]"
                >
                  {t("In the fast-paced Home and Maintenance Services industry, DizLog POS proves to be a game-changing asset for businesses and professionals alike. From managing appointments for various services, such as plumbing, electrician work, or home cleaning, to tracking job progress and client details, DizLog POS streamlines operations and ensures that nothing falls through the cracks.")}
                </span>
                <div className="mt-8">
                  <CTAButtons requestToDemoPath="/lifetime-deals/retail-service" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {SubContents.map((item) => (
          <SingleContentComponentWithBulletList data={item} />
        ))}
      </div>
      <LearnHowToUseDizlogComponent/>
      <TestimonialsRowPositionComponent testimonials={ourTestimonials}/>
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default HomeMaintenaceService;
