import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footer_home from "../assets/Footer_Home@2x.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import SingleContentComponentWithBulletList from "../components/SingleContentComponentWithBulletList";
import hairCareSalonn from "../assets/hairCareSalonn.png";
import bookingICON from "../assets/bookingICON.png";
import bookingHairKeratin from "../assets/bookingHairKeratin.png";
import appointmentProf from "../assets/appointmentProf.png";
import simpliFiedd from "../assets/simpliFiedd.png";
import streamPayrollICON from "../assets/streamPayrollICON.png";
import payrollSettingsBus from "../assets/payrollSettingsBus.png";
import dataDriverIcon from "../assets/dataDriverIcon.png";
import efficientICon from "../assets/efficientICon.png";
import hairCareSalonmpos from "../assets/hairCareSalonmpos.png";
import bookServiceHaircut from "../assets/bookServiceHaircut.png";
import newInvoiceHair from "../assets/newInvoiceHair.png";
import topCatHair from "../assets/topCatHair.png";
import LearnHowToUseDizlogComponent from "../components/LearnHowToUseDizlogComponent";
import TestimonialsRowPositionComponent from "../components/TestimonialsRowPositionComponent";
import AriannaHernandez from "../assets/AriannaHernandez.png";
import LuisLopez from "../assets/LuisLopez.png";
import nailCUstomDisc from "../assets/nailCUstomDisc.png";
import CTAButtons from "../components/CTAButtons";



function HairCareBeauty() {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  const ourTestimonials = [
    {
      content: t("“As a Hair Care Salon owner, I can confidently say that DizLog POS has been a game-changer for my business. The appointment scheduling feature has simplified our daily operations, allowing us to easily manage client bookings and staff schedules, which has resulted in reduced wait times and increased customer satisfaction.”"),
      name: t("Arianna Hernandez"),
      designation: t("Hair Care Salon Owner"),
      imageSrc: AriannaHernandez
    },
    {
      content: t("“One of the standout features for us has been the client database, which enables us to keep track of individual preferences. This has allowed us to personalize each client's experience, leading to higher client retention. Moreover, the real-time analytics have provided valuable insights into our sales trends and popular services. ”"),
      name: t("Luis Lopez"),
      designation: t("Hair Care Salon Owner"),
      imageSrc: LuisLopez
    }
  ]

  let SubContents = [
    {
      isImageRight: false,
      backgroundColor: "#FFFFFF",
      minHeight: "475px",
      image: {
        src: bookingHairKeratin,
        height: "267.19px",
      },
      title: t("Efficient Salon Management with Dizlog"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: efficientICon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Seamless Client Checkout"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog for smooth and efficient payment processing, ensuring clients enjoy a hassle-free checkout experience after their hair services."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Accurate Service Pricing and Discounts"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Ensure precise pricing and automated application of discounts through Dizlog, enhancing transparency and client satisfaction during billing."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Flexible Payment Options"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog supports diverse payment methods, such as credit cards and digital wallets, providing clients with flexible and convenient payment"),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#004AAD",
      minHeight: "475px",
      image: {
        src: hairCareSalonmpos,
        height: "291.45px",
      },
      title: t("Optimal Appointment and Product Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: appointmentProf,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Real-time Appointment Tracking"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog to manage appointments in real-time, optimizing scheduling and reducing wait times for clients seeking hair care services."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Product Alerts"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog generates automatic alerts for low product stock, ensuring timely restocking of hair care products and preventing inventory shortages."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Centralized Product Control"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Maintain a centralized view of hair care products across your salon and online store with Dizlog, reducing overstocking and improving product availability."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "590.77px",
      image: {
        src: bookServiceHaircut,
        height: "490.77px",
      },
      title: t("Seamlessly Integrated Online Booking"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: bookingICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Comprehensive Online Services Menu"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Create an extensive online menu of hair care services with Dizlog, allowing clients to explore and conveniently book their desired services."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("User-friendly Online Booking"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog simplifies the online booking process, providing clients with an intuitive platform to select and schedule their preferred hair care appointments."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Real-time Booking Synchronization"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Ensure real-time synchronization of appointments between your salon and online platform using Dizlog, enhancing convenience for clients."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "557.92px",
      image: {
        src: newInvoiceHair,
        height: "397.92px",
      },
      title: t("Simplified Invoicing and Payment Handling"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: simpliFiedd,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Invoicing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Generate accurate and automated invoices with Dizlog, offering clients clear documentation of services and streamlining the invoicing process."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Flexible Payment Methods"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog supports various payment options, allowing clients to pay using their preferred methods and enhancing the ease of payment."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Payment Tracking and Reminders"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog to track payment statuses and send automated reminders, reducing outstanding balances and ensuring effective cash flow management."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#004AAD",
      minHeight: "475px",
      image: {
        src: payrollSettingsBus,
        height: "270px",
      },
      title: t("Efficient Payroll and Staff Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: streamPayrollICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Streamlined Employee Scheduling"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog for optimized staff scheduling, ensuring proper coverage during peak hours and enhancing salon efficiency."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Accurate Timekeeping"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog accurately records employee work hours, simplifying payroll processing and ensuring equitable compensation for your hair care staff."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Performance Insights"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Analyze employee performance data within Dizlog to make informed decisions about training, rewards, and professional growth opportunities."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#FEF7EC",
      minHeight: "483.38px",
      image: {
        src: topCatHair,
        height: "323.38px",
      },
      title: t("Data-driven Insights for Informed Choices"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: dataDriverIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Service Analytics and Trends"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Leverage Dizlog analytics to gain insights into popular hair care services and client preferences, guiding service offerings and marketing strategies."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Product Reports and Forecasting"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Generate comprehensive product reports and forecasts with Dizlog, aiding in inventory management and ensuring product availability."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Employee Productivity Analysis"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog data to assess employee productivity and performance, facilitating performance evaluations and enhancing team productivity."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#FFFFFF",
      minHeight: "416.95px",
      image: {
        src: nailCUstomDisc,
        height: "256.95px",
      },
      title: t("Enhanced Client Experience and Engagement"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: streamPayrollICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Personalized Client Interactions"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize client data within Dizlog to provide personalized recommendations and tailored services, enhancing the hair care salon experience."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Loyalty Programs and Rewards"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Implement loyalty programs through Dizlog to reward loyal clients, fostering client retention and increasing engagement."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
  ];
  return (
    <>
      <Header i18n={i18n} screen="business-types" />
      <div id="businessRetail">
        <section>
          <div className="min-h-[366px] bg-[#FEF7EC] flex flex-col md:flex-row gap-2 md:gap-0">
            <div className="flex flex-1 items-end justify-center">
              <img src={hairCareSalonn} className="h-[337.44px] w-auto" />
            </div>
            <div className="flex flex-1 justify-center items-center py-4">
              <div className="flex flex-col w-[80%]">
                <span
                  className="font-[Raleway] font-[700] 
                  text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px] 
                  leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px] text-[#00215D]"
                >
                  {t("DizLog for Hair Care Salons")}
                </span>
                <span
                  className="mt-2 font-[Roboto] font-[400] 
                  text-[14px] leading-[19px] text-[#6B6B6B]"
                >
                  {t("DizLog POS offers a plethora of benefits to Hair Care Salons businesses, making it an indispensable tool for their day-to-day operations. Firstly, this comprehensive point-of-sale system streamlines the entire salon management process, allowing salon owners to efficiently handle appointments, inventory, and customer records all in one place. With an intuitive interface, staff can easily schedule appointments, track client preferences, and manage employee shifts effortlessly. Moreover, DizLog POS simplifies the checkout process, facilitating secure and swift transactions, while also offering multiple payment options for customer convenience.")}
                </span>
                <div className="mt-8">
                  <CTAButtons />
                </div>
              </div>
            </div>
          </div>
        </section>
        {SubContents.map((item) => (
          <SingleContentComponentWithBulletList data={item} />
        ))}
      </div>
      <LearnHowToUseDizlogComponent/>
      <TestimonialsRowPositionComponent testimonials={ourTestimonials}/>
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default HairCareBeauty;
