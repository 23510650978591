import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footer_home from "../assets/Footer_Home@2x.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import SingleContentComponentWithBulletList from "../components/SingleContentComponentWithBulletList";
import automotiveService from "../assets/automotiveService.png";
import bookingICON from "../assets/bookingICON.png";
import optimizeInvent from "../assets/optimizeInvent.png";
import adjustSTockAutomotive from "../assets/adjustSTockAutomotive.png";
import automotServ from "../assets/automotServ.png";
import bookingOilChange from "../assets/bookingOilChange.png";
import appointmentProf from "../assets/appointmentProf.png";
import simpliFiedd from "../assets/simpliFiedd.png";
import topItemsAutomotive from "../assets/topItemsAutomotive.png";
import streamPayrollICON from "../assets/streamPayrollICON.png";
import payrollSettingsBus from "../assets/payrollSettingsBus.png";
import dataDriverIcon from "../assets/dataDriverIcon.png";
import invoiceOilChange from "../assets/invoiceOilChange.png";
import centralisedOPS from "../assets/centralisedOPS.png";
import LearnHowToUseDizlogComponent from "../components/LearnHowToUseDizlogComponent";
import TestimonialsRowPositionComponent from "../components/TestimonialsRowPositionComponent";
import MarkFlores from "../assets/MarkFlores.png";
import AndrewdelaCruz from "../assets/AndrewdelaCruz.png";
import CTAButtons from "../components/CTAButtons";



function AutomotiveService() {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;


  const ourTestimonials = [
    {
      content: t("“As an owner of an automotive repair shop, I can confidently say that DizLog POS has been a game-changer for my business. From the moment we implemented this remarkable system, our efficiency and customer service have reached new heights. Managing appointments and coordinating with our mechanics have become seamless, eliminating any scheduling conflicts and ensuring timely service delivery.”"),
      name: t("Mark Flores"),
      designation: t("Mechanic"),
      imageSrc: MarkFlores
    },
    {
      content: t("“Since adopting DizLog POS, we have witnessed increased customer satisfaction and loyalty, which has translated into more repeat business and positive word-of-mouth referrals. DizLog POS has truly empowered us to provide exceptional automotive services, and I highly recommend it to anyone in the Automotive Services industry looking to elevate their business to the next level.”"),
      name: t("Andrew de la Cruz"),
      designation: t("Mechanic"),
      imageSrc: AndrewdelaCruz
    }
  ]


  let SubContents = [
    {
      isImageRight: false,
      backgroundColor: "#FFFFFF",
      minHeight: "650.77px",
      image: {
        src: bookingOilChange,
        height: "490.77px",
      },
      title: t("Seamless Vehicle Service Booking with Dizlog"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: bookingICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Effortless Appointment Scheduling"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog for smooth and hassle-free vehicle service booking, allowing customers to schedule automotive services with ease."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Real-time Availability"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog offers real-time visibility into service availability, enabling customers to select suitable time slots based on their preferences and your workshop schedule."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Service Reminders"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Set up automated service reminders through Dizlog, reducing missed appointments and ensuring a well-organized scheduling process for both customers and your team."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#004AAD",
      minHeight: "478px",
      image: {
        src: adjustSTockAutomotive,
        height: "318px",
      },
      title: t("Optimal Parts and Inventory Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: optimizeInvent,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Efficient Parts Tracking "),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog to monitor and manage essential automotive parts and inventory, ensuring your services have the necessary components."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Low Inventory Alerts"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Receive automated alerts for low inventory levels via Dizlog, allowing timely restocking and preventing service delays."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Inventory Reports and Insights"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Generate comprehensive inventory reports and insights using Dizlog data, assisting in inventory optimization and enhancing service efficiency."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "475px",
      image: {
        src: automotServ,
        height: "289px",
      },
      title: t("User-friendly Online Service Access"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: appointmentProf,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Intuitive Online Booking"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Create a user-friendly online store with Dizlog, enabling customers to easily browse and book automotive services at their convenience."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Secure Payment Processing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog ensures secure online payment processing, allowing customers to make payments in advance and enhancing the overall service experience."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("24/7 Service Availability"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("With Dizlog, your services are available for online booking around the clock, providing customers with flexibility and increasing accessibility."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    { 
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "557.92px",
      image: {
        src: invoiceOilChange,
        height: "397.92px",
      },
      title: t("Simplified Invoicing and Payment Handling"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: simpliFiedd,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Invoicing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog to generate automated invoices, providing customers with transparent and accurate billing for the automotive services they receive."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Payment Options"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog supports various payment methods, allowing customers to pay for services using their preferred options and enhancing convenience."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Payment Tracking and Reminders"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog to track payment statuses and send automated reminders, ensuring timely payments and improving your financial management."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#004AAD",
      minHeight: "493px",
      image: {
        src: payrollSettingsBus,
        height: "270px",
      },
      title: t("Efficient Staff Management and Payroll"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: streamPayrollICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Staff Scheduling"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog for efficient staff scheduling, ensuring your team is well-organized and equipped to provide timely automotive services."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Accurate Timekeeping"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog accurately records employee work hours, simplifying payroll processing and ensuring fair compensation for your team members."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Performance Tracking and Improvement"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Leverage Dizlog data to assess employee performance and identify opportunities for growth and enhancement, fostering a motivated workforce."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "483.38px",
      image: {
        src: topItemsAutomotive,
        height: "323.38px",
      },
      title: t("Insights-driven Quality Automotive Services"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: dataDriverIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Service Analytics"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog analytics to gain insights into service performance, customer preferences, and trends, guiding informed decisions to enhance your automotive offerings."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Resource Utilization Reports"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Generate resource utilization reports using Dizlog data, enabling you to optimize resource allocation and streamline service processes."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Financial Insights"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
           t("Gain financial insights through Dizlog data analysis, empowering strategic financial decisions for your automotive services."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "493px",
      image: {
        src: payrollSettingsBus,
        height: "323.38px",
      },
      title: t("Centralized Operations and Accessibility "),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: centralisedOPS,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Integrated Business Hub"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog serves as a central hub for managing appointments, inventory, invoicing, payroll, and employee information, simplifying your automotive service operations."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title:t("Cloud-based Access"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Access Dizlog remotely from anywhere with an internet connection, enabling you to manage your automotive business on-the-go and stay connected to your operations."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Scalable Support"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog scales seamlessly to accommodate increased demand, ensuring continued support for your success."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
  ];

  return (
    <>
      <Header i18n={i18n} screen="business-types" />
      <div id="businessRetail">
        <section>
          <div className="min-h-[366px] bg-[#FEF7EC] flex flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row">
            <div className="flex flex-1 items-end justify-center">
              <img src={automotiveService} className="h-[337.44px] w-auto" />
            </div>
            <div className="flex flex-1 justify-center items-center py-4">
              <div className="flex flex-col w-[80%]">
                <span
                  className="font-[Raleway] font-[700] 
                  text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px] 
                  leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px]
                   text-[#00215D]"
                >
                  {t("DizLog for Automotive Services")}
                </span>
                <span
                  className="mt-2 font-[Roboto] font-[400] 
                  text-[14px] leading-[19px] text-[#6B6B6B]"
                >
                  {t("DizLog POS offers a powerful solution that can revolutionize the way businesses operate in the Automotive Services industry. With its comprehensive features and user-friendly interface, this cutting-edge system empowers automotive service providers to optimize their operations, enhance customer service, and boost profitability.")}
                </span>
                <div className="mt-8">
                  <CTAButtons requestToDemoPath="/lifetime-deals/retail-service" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {SubContents.map((item) => (
          <SingleContentComponentWithBulletList data={item} />
        ))}
      </div>
      <LearnHowToUseDizlogComponent/>
      <TestimonialsRowPositionComponent testimonials={ourTestimonials}/>
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default AutomotiveService;
