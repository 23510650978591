import React from "react";

import image289 from "../assets/image 289@3x.png";
import image290 from "../assets/image 290@3x.png";
import image291 from "../assets/image 291@3x.png";
import image292 from "../assets/image 292@3x.png";
import image293 from "../assets/image 293@3x.png";
import image294 from "../assets/image 294@3x.png";
import image295 from "../assets/image 295@3x.png";
import image296 from "../assets/image 296@3x.png";
import image297 from "../assets/image 297@3x.png";
import image308 from "../assets/image 308@3x.png";
import image306 from "../assets/image 306@3x.png";
import image299 from "../assets/image 299@3x.png";
import image300 from "../assets/image 300@3x.png";
import image309 from "../assets/image 309@3x.png";
import image304 from "../assets/image 304@3x.png";
import image305 from "../assets/image 305@3x.png";
import image307 from "../assets/image 307@3x.png";
import { useTranslation } from "react-i18next";

const clientLogos = {
  top: [
    image289,
    image290,
    image291,
    image292,
    image293,
    image294,
    image295,
    image296,
    image297,
  ],
  bottom: [
    image308,
    image306,
    image299,
    image300,
    image309,
    image304,
    image305,
    image307,
  ],
};

function TrustedClientList() {
  const { t, i18n } = useTranslation();

  return (
    <section>
      <div className="bg-[#FFFFFF] flex flex-col p-6 justify-center">
        <div className="flex flex-col items-center">
          <span className="font-[Raleway] font-[700] text-[22px] xl:text-[38px] lg:text-[38px] md:text-[38px] sm:text-[38px] text-[#00215D]">
            {t("Trusted by 1,000+ businesses like yours")}
          </span>
        </div>

        <div className="flex flex-col items-center gap-y-10 w-full my-10 max-w-[1166px] mx-auto">
          <div className="grid grid-cols-9 items-center gap-x-4 w-full">
            {clientLogos.top.map((logo) => (
              <img className="w-[86px]" src={logo} alt="client logo" />
            ))}
          </div>
          <div className="grid grid-cols-8 items-center gap-x-4 w-full">
            {clientLogos.bottom.map((logo) => (
              <img className="w-[86px]" src={logo} alt="client logo" />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default TrustedClientList;
