import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footer_home from "../assets/Footer_Home@2x.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import educationTraining from "../assets/educationTraining.png";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import SingleContentComponentWithBulletList from "../components/SingleContentComponentWithBulletList";
import newInvoiceEduc from "../assets/newInvoiceEduc.png";
import bookingICON from "../assets/bookingICON.png";
import optimizeInvent from "../assets/optimizeInvent.png";
import adjustStocksEduc from "../assets/adjustStocksEduc.png";
import userFriendlyEduc from "../assets/userFriendlyEduc.png";
import mathTutor from "../assets/mathTutor.png";
import appointmentProf from "../assets/appointmentProf.png";
import simpliFiedd from "../assets/simpliFiedd.png";
import topItemsTuror from "../assets/topItemsTuror.png";
import streamPayrollICON from "../assets/streamPayrollICON.png";
import payrollSettingsBus from "../assets/payrollSettingsBus.png";
import dataDriverIcon from "../assets/dataDriverIcon.png";
import centralisedOPS from "../assets/centralisedOPS.png";
import LearnHowToUseDizlogComponent from "../components/LearnHowToUseDizlogComponent";
import TestimonialsRowPositionComponent from "../components/TestimonialsRowPositionComponent";
import LillianTomas from "../assets/LilianTomas.png";
import IanAndrada from "../assets/IanAndrada.png";
import CTAButtons from "../components/CTAButtons";



function EducationAndTrainingService() {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;


  const ourTestimonials = [
    {
      content: t("“As an educator in the Education and Training Services industry, I can confidently say that DizLog POS has been a game-changer for our institution. From the moment we integrated this remarkable system, our administrative tasks have become a breeze. The enrollment process, which used to be time-consuming and prone to errors, is now seamless and efficient, allowing us to focus more on delivering quality education.”"),
      name: t("Lillian Tomas"),
      designation: t("Teacher"),
      imageSrc: LillianTomas
    },
    {
      content: t("“Our students have noticed the difference, and their engagement and satisfaction have increased significantly. DizLog POS has truly elevated our institution, providing us with the tools we need to excel in the Education and Training Services industry. I highly recommend it to any educational institution or training provider looking to streamline their operations and deliver an exceptional learning experience.”"),
      name: t("Ian Andrada"),
      designation: t("Teacher"),
      imageSrc: IanAndrada
    }
  ]


  let SubContents = [
    {
      isImageRight: false,
      backgroundColor: "#FFFFFF",
      minHeight: "650.77px",
      image: {
        src: mathTutor,
        height: "490.77px",
      },
      title: t("Streamlined Enrollment and Instructor Management with Dizlog"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: bookingICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Effortless Course Booking and Instructor Assignment"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog for smooth course enrollment and seamless assignment of instructors, allowing students to register for education and training services and be matched with suitable instructors."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Real-time Availability and Scheduling"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog provides real-time visibility into course and instructor availability, enabling students to select suitable schedules based on their preferences and your class timetable."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Reminders and Instructor Notifications"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Set up automated course and instructor reminders through Dizlog, reducing no-shows and ensuring a well-organized enrollment process for both students and your team."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#004AAD",
      minHeight: "478px",
      image: {
        src: adjustStocksEduc,
        height: "318px",
      },
      title: t("Optimal Resource and Material Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: optimizeInvent,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Efficient Resource Tracking"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog to monitor and manage essential course resources and materials, ensuring your education and training services have the necessary tools."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Low Inventory Alerts"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Receive automated alerts for low inventory levels via Dizlog, allowing timely restocking and preventing any disruptions in course delivery."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Resource Reports and Insights"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Generate comprehensive resource reports and insights using Dizlog data, assisting in resource allocation decisions and enhancing the learning experience."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "475px",
      image: {
        src: userFriendlyEduc,
        height: "289px",
      },
      title: t("User-friendly Online Course Access"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: appointmentProf,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Intuitive Online Enrollment"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Create a user-friendly online store with Dizlog, enabling students to easily explore and enroll in education and training courses at their convenience."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Secure Payment Processing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog ensures secure online payment processing, allowing students to make advance payments for courses and enhancing the overall learning journey."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("24/7 Course Availability"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("With Dizlog, your courses become available for enrollment at any time, providing students with flexibility and increasing accessibility."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "557.92px",
      image: {
        src: newInvoiceEduc,
        height: "397.92px",
      },
      title: t("Simplified Billing and Payment Handling"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: simpliFiedd,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Invoicing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog to generate automated course invoices, providing students with transparent and accurate billing for the education and training services."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Payment Versatility"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog supports various payment methods, allowing students to pay for courses using their preferred options and enhancing convenience."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Payment Tracking and Reminders"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog to track payment statuses and send automated reminders, ensuring timely payments and improving financial management."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#004AAD",
      minHeight: "493px",
      image: {
        src: payrollSettingsBus,
        height: "270px",
      },
      title: t("Effective Instructor Management and Payroll"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: streamPayrollICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Instructor Performance Tracking"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog to monitor instructor performance and effectiveness, ensuring a comprehensive view of each instructor's teaching impact."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Accurate Timekeeping and Compensation"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog accurately records instructor work hours, simplifying payroll processing and ensuring fair compensation for teaching efforts."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Professional Development Insights"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Leverage Dizlog data to assess instructor contributions and identify opportunities for professional growth and enhancement, fostering an environment of continuous improvement."),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "483.38px",
      image: {
        src: topItemsTuror,
        height: "323.38px",
      },
      title: t("Insights-driven Excellence in Education"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: dataDriverIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Learning Analytics"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Utilize Dizlog analytics to gain insights into course performance, student preferences, and trends, guiding informed decisions to enhance your offerings."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Resource Utilization Reports"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Generate resource utilization reports using Dizlog data, enabling you to optimize resource allocation and improve the overall education and training experience."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Financial Insights"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Gain financial insights through Dizlog data analysis, empowering strategic financial decisions for your education and training services."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "493px",
      image: {
        src: payrollSettingsBus,
        height: "323.38px",
      },
      title: t("Centralized Operations and Accessibility"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: centralisedOPS,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Integrated Learning Hub"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Dizlog serves as a central hub for managing course enrollments, resources, invoicing, payroll, and instructor information, simplifying your operations."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Cloud-based Access"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("Access Dizlog remotely from anywhere with an internet connection, enabling remote management and real-time oversight of your education and training services."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Scalable Support"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description:
            t("As your education and training services expand, Dizlog scales seamlessly to accommodate increased demand, ensuring continued support for your growth."),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
  ];

  return (
    <>
      <Header i18n={i18n} screen="business-types" />
      <div id="businessRetail">
        <section>
          <div className="min-h-[366px] bg-[#FEF7EC] flex  flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row">
            <div className="flex flex-1 items-end justify-center">
              <img src={educationTraining} className="h-[90%] max-h-[500px] w-auto" />
            </div>
            <div className="flex flex-1 justify-center items-center py-4">
              <div className="flex flex-col w-[80%]">
                <span
                  className="font-[Raleway] font-[700] 
                  text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px] 
                  leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px]
                   text-[#00215D]"
                >
                  {t("DizLog for Education and Training Services")}
                </span>
                <span
                  className="mt-2 font-[Roboto] font-[400] 
                  text-[14px] leading-[19px] text-[#6B6B6B]"
                >
                  {t("DizLog POS offers a revolutionary solution that can significantly benefit the Education and Training Services industry. With its innovative features and user-friendly interface, this cutting-edge system empowers educational institutions and training providers to optimize their operations and enhance the learning experience. DizLog POS simplifies the enrollment process, allowing seamless registration and payment processing for students and trainees.")}
                </span>
                <div className="mt-8">
                  <CTAButtons requestToDemoPath="/lifetime-deals/retail-service" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {SubContents.map((item) => (
          <SingleContentComponentWithBulletList data={item} />
        ))}
      </div>
      <LearnHowToUseDizlogComponent/>
      <TestimonialsRowPositionComponent testimonials={ourTestimonials}/>
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default EducationAndTrainingService;
