import React, { Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { ConfigProvider } from "antd";
import RentalServices from "./screens/RentalServices";
import Blog from "./screens/Blog";
import VideoSupport from "./screens/VideoSupport";
import ReadBlog from "./screens/ReadBlog";
import HomeMaintenaceService from "./screens/HomeMaintenaceService";
import TechnologyAndITService from "./screens/TechnologyAndITService";
import EventPlanningEntertainmentService from "./screens/EventPlanningEntertainmentService";
import EducationAndTrainingService from "./screens/EducationAndTrainingService";
import AutomotiveService from "./screens/AutomotiveService";
import HairCareBeauty from "./screens/HairCareBeauty";
import SkinCareAndSpaBeauty from "./screens/SkinCareAndSpaBeauty";
import NailCareBeauty from "./screens/NailCareBeauty";
import WellnessAndHolisticBeauty from "./screens/WellnessAndHolisticBeauty";
import TattoosAndPiercingBeauty from "./screens/TattoosAndPiercingBeauty";
import ArtsCraftsNovelty from "./screens/ArtsCraftsNovelty";
import VehiclesSuppliesParts from "./screens/VehiclesSuppliesParts";
import GeneralMerchandise from "./screens/GeneralMerchandise";
import GroceryStore from "./screens/GroceryStore";
import Furniture from "./screens/Furniture";
import FastFoodAndRestaurant from "./screens/FastFoodAndRestaurant";
import CafeCoffeeShop from "./screens/CafeCoffeeShop";
import BarsAndBreweries from "./screens/BarsAndBreweries";
import FoodServiceCatering from "./screens/FoodServiceCatering";
import B2bEcommerce from "./screens/B2bEcommerce";
import LiveDemo from "./screens/LiveDemo";
import ContactUsPage from "./screens/ContactUsPage";
import Accounting from "./screens/Accounting";
import Restaurant from "./screens/Restaurant";
import RetailV2 from "./screens/RetailV2";
import Beauty from "./screens/Beauty";
import Services from "./screens/Services";

const Home = React.lazy(() => import("./screens/HomeV3"));
const Products = React.lazy(() => import("./screens/ProductsV2"));
const WhyDigLog = React.lazy(() => import("./screens/WhyDigLogv1"));
const OnlineStore = React.lazy(() => import("./screens/OnlineStore"));
const FoodandBeverages = React.lazy(() =>
  import("./screens/BusinessFoodAndBeverage")
);
const BusinessVirtualProducts = React.lazy(() =>
  import("./screens/BusinessVirtualProducts")
);
const BusinessProffesionalService = React.lazy(() =>
  import("./screens/BusinessProffesionalService")
);
const ProfessionalServices = React.lazy(() =>
  import("./screens/ProfessionalServices")
);
const Retail = React.lazy(() => import("./screens/Retail"));
const GetHelp = React.lazy(() => import("./screens/GetHelp"));
const GetStarted = React.lazy(() => import("./screens/GetStarted"));
const GetHelpInner = React.lazy(() => import("./screens/GetHelpInner"));
const PrivacyPolicy = React.lazy(() => import("./screens/PrivacyPolicy"));
const TermsAndConditions = React.lazy(() =>
  import("./screens/TermsAndConditions")
);
const ContactUs = React.lazy(() => import("./screens/ContactUs"));
const Marketplace = React.lazy(() => import("./screens/Marketplace"));
const PartnerProgram = React.lazy(() => import("./screens/PartnerProgram"));
const Pricing = React.lazy(() => import("./screens/PricingV7"));
const HardWareSolutions = React.lazy(() =>
  import("./screens/HardWareSolutions")
);
const PaymentPartners = React.lazy(() => import("./screens/PaymentPartners"));
const LogisticsPartners = React.lazy(() =>
  import("./screens/LogisticsPartners")
);
const Subsidaries = React.lazy(() => import("./screens/Subsidaries"));
const BusinessRetails = React.lazy(() => import("./screens/BusinessRetails"));
const LifeTimeDeals = React.lazy(() => import("./screens/LifeTimeDeals"));
const PromoRestaurant = React.lazy(() => import("./screens/PromoRestaurant"));
const PromoRetail = React.lazy(() => import("./screens/PromoRetail"));
const PromoRetailService = React.lazy(() =>
  import("./screens/PromoRetailService")
);

function App() {
  if (
    window.location.href.includes("my") &&
    !window.location.href.includes("ms")
  ) {
    window.location.href = "/ms";
  }

  // Modal.setAppElement('#root');

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "F09000",
          colorBorder: "#F5B13F",
          colorTextQuaternary: "#F5B13F",
          colorPrimaryHover: "#FAD37F",
          colorBgContainer: "#FEF7EC",
          fontFamily: "Roboto",
        },
        components: {
          Form: {
            verticalLabelPadding: 0,
          },
          Card: {
            paddingLG: 0,
          },
          Button: {
            defaultBorderColor: "#F09000",
            colorText: "#F09000",
            lineWidth: 2,
            fontWeight: 700,
            colorBgContainer: "#FFF",
            primaryShadow: "0 2px 0 rgba(0, 0, 0, 0.02)",
          },
        },
      }}
    >
      <Router>
        <Suspense fallback={<div></div>}>
          <div className="App">
            <Routes>
              <Route
                path="/business-types/online-store"
                element={<OnlineStore />}
              />
              <Route
                path="/business-types/food-and-beverages"
                element={<FoodandBeverages />}
              />
              <Route
                path="/business-types/virtual-products"
                element={<BusinessVirtualProducts />}
              />
              <Route
                path="/business-types/rental-services"
                element={<RentalServices />}
              />
              {/* new routes */}
              {/* services */}
              <Route
                path="/business-types/services/proffesional-services"
                element={<BusinessProffesionalService />}
              />
              <Route
                path="/business-types/services/home-maintenance-services"
                element={<HomeMaintenaceService />}
              />
              <Route
                path="/business-types/services/technology-and-it-services"
                element={<TechnologyAndITService />}
              />
              <Route
                path="/business-types/services/event-planning-entertainment-services"
                element={<EventPlanningEntertainmentService />}
              />
              <Route
                path="/business-types/services/education-training-services"
                element={<EducationAndTrainingService />}
              />
              <Route
                path="/business-types/services/automotive-services"
                element={<AutomotiveService />}
              />
              {/* services */}

              {/* beauty */}
              <Route
                path="/business-types/beauty/hair-care"
                element={<HairCareBeauty />}
              />
              <Route
                path="/business-types/beauty/skincare-and-spa"
                element={<SkinCareAndSpaBeauty />}
              />
              <Route
                path="/business-types/beauty/nail-care"
                element={<NailCareBeauty />}
              />
              <Route
                path="/business-types/beauty/wellness-holistic"
                element={<WellnessAndHolisticBeauty />}
              />
              <Route
                path="/business-types/beauty/tattoo-piercing"
                element={<TattoosAndPiercingBeauty />}
              />
              {/* beauty */}

              {/*  retail */}
              <Route
                path="/business-types/retail/arts-crafts-novelty"
                element={<ArtsCraftsNovelty />}
              />
              <Route
                path="/business-types/retail/vehicles-supplies-part"
                element={<VehiclesSuppliesParts />}
              />
              <Route
                path="/business-types/retail/general-merchandise"
                element={<GeneralMerchandise />}
              />
              <Route
                path="/business-types/retail/grocery-store"
                element={<GroceryStore />}
              />
              <Route
                path="/business-types/retail/furniture"
                element={<Furniture />}
              />
              {/*  retail */}

              {/* food and beverage */}
              <Route
                path="/business-types/fastfood-beverages/fastfood-and-restaurant"
                element={<FastFoodAndRestaurant />}
              />
              <Route
                path="/business-types/fastfood-beverages/cafe-coffee-shop"
                element={<CafeCoffeeShop />}
              />
              <Route
                path="/business-types/fastfood-beverages/bars-breweries"
                element={<BarsAndBreweries />}
              />
              <Route
                path="/business-types/fastfood-beverages/food-service-catering"
                element={<FoodServiceCatering />}
              />
              {/* food and beverage */}

              {/* new routes */}

              <Route path="/business-types/retail" element={<Retail />} />
              <Route
                path="/business-types/professional-services"
                element={<ProfessionalServices />}
              />
              <Route
                path="/products/invoicing-quotations"
                element={<Products product_href={"invoicing-quotations"} />}
              />
              <Route
                path="/products/cash-credit-book"
                element={<Products product_href={"cash-credit-book"} />}
              />
              <Route
                path="/products/payroll"
                element={<Products product_href={"payroll"} />}
              />
              <Route
                path="/products/employee-payroll-management"
                element={<Products product_href={"employee-management"} />}
              />
              <Route
                path="/products/online-store"
                element={<Products product_href={"online-store"} />}
              />
              <Route
                path="/products/inventory-management"
                element={<Products product_href={"inventory-management"} />}
              />
              <Route
                path="/products/pos"
                element={<Products product_href={"pos"} />}
              />
              <Route path="/why-dizlog" element={<WhyDigLog />} />
              <Route path="/get-help" element={<GetHelp />} />
              <Route
                path="/get-help/getting-started"
                element={<GetHelpInner type="get-started" />}
              />
              <Route
                path="/get-help/mpos"
                element={<GetHelpInner type="mpos" />}
              />
              <Route
                path="/get-help/employee"
                element={<GetHelpInner type="employee" />}
              />
              <Route
                path="/get-help/cash-credit"
                element={<GetHelpInner type="cash-credit" />}
              />
              <Route
                path="/get-help/payments"
                element={<GetHelpInner type="payments" />}
              />
              <Route
                path="/get-help/payroll"
                element={<GetHelpInner type="payroll" />}
              />
              <Route
                path="/get-help/reports"
                element={<GetHelpInner type="reports" />}
              />

              <Route path="/get-started" element={<GetStarted />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              {/* <Route path="/contact-us" element={<ContactUs />} /> */}
              <Route path="/marketplace" element={<Marketplace />} />
              <Route path="/" element={<Home lang="en" />} />
              <Route path="/en" element={<Home lang="en" />} />
              <Route path="/af" element={<Home lang="af" />} />
              <Route path="/id" element={<Home lang="id" />} />
              <Route path="/es" element={<Home lang="es" />} />
              <Route path="/fr" element={<Home lang="fr" />} />
              <Route path="/ms" element={<Home lang="ms" />} />
              <Route path="/jp" element={<Home lang="jp" />} />
              <Route path="/cn" element={<Home lang="cn" />} />
              <Route path="/vi" element={<Home lang="vi" />} />
              <Route path="/hi" element={<Home lang="hi" />} />
              <Route path="/bn" element={<Home lang="bn" />} />
              <Route
                path="/partner-program"
                element={<PartnerProgram lang="en" />}
              />
              <Route path="/pricing" element={<Pricing lang="en" />} />
              <Route
                path="/hardware-solutions"
                element={<HardWareSolutions lang="en" />}
              />
              <Route
                path="/payment-partners"
                element={<PaymentPartners lang="en" />}
              />
              <Route
                path="/logistics-partners"
                element={<LogisticsPartners lang="en" />}
              />
              <Route
                path="/regional-offices"
                element={<Subsidaries lang="en" />}
              />
              <Route
                path="/business-types/business-retails"
                element={<BusinessRetails lang="en" />}
              />
              <Route
                path="/lifetime-deals"
                element={<LifeTimeDeals lang="en" />}
              />
              <Route
                path="/lifetime-deals/restaurant"
                element={<PromoRestaurant lang="en" />}
              />
              <Route
                path="/lifetime-deals/retail"
                element={<PromoRetail lang="en" />}
              />
              <Route
                path="/lifetime-deals/retail-service"
                element={<PromoRetailService />}
              />
              <Route path="/blogs" element={<Blog lang="en" />} />
              <Route path="/blogs/:index" element={<ReadBlog lang="en" />} />
              <Route
                path="/video-support"
                element={<VideoSupport lang="en" />}
              />
              <Route
                path="/b2b-e-commerce"
                element={<B2bEcommerce lang="en" />}
              />
              <Route path="/live-demo" element={<LiveDemo lang="en" />} />
              <Route path="/contact-us" element={<ContactUsPage lang="en" />} />
              <Route path="/accounting" element={<Accounting lang="en" />} />
              <Route path="/retail" element={<RetailV2 lang="en" />} />
              <Route path="/beauty" element={<Beauty lang="en" />} />
              <Route path="/services" element={<Services lang="en" />} />
              <Route path="/restaurant" element={<Restaurant />} />
              <Route path="*" element={<Home lang="en" />} />
            </Routes>
          </div>
        </Suspense>
      </Router>
    </ConfigProvider>
  );
}

export default App;
