import React, { useState } from "react";

//components
import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import FAQ from "../components/FAQ";
import Topics from "../components/Topics";
import Reviews from "../components/Reviews";

//images
import Footer_GetHelp from "../assets/Footer_GetHelp@2x.png";
import accountingBanner from "../assets/accounting_banner.png";

//logos
import GetHelp_Icon_01 from "../assets/GetHelp_Icon_01.svg";
import { useTranslation } from "react-i18next";

import howCanWeHelp from "../assets/howCanWeHelp.png";
import SignUpLink from "../components/SignUpLink";
import DownloadLinks from "../components/DownloadLinks";
import Acccounting from "../assets/Accounting-02.png";
import invoiceQuotations from "../assets/invoiceQuotations.png";
import inventoryManagement from "../assets/inventoryManagement.png";
import HomePOS from "../assets/POS-02.png";
import employeeManagement from "../assets/employeeManagement (1).png";
import HomeCashAndCreditBook from "../assets/home_cashandcreditbook.png";
import onlineStore from "../assets/onlineStore.png";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import banRecon from "../assets/banRecon.png";
import salesTaxTrack from "../assets/salesTaxTrack.png";
import financialRep from "../assets/financialRep.png";
import ledgerMgmt from "../assets/ledgerMgmt.png";
import payrollMgmt from "../assets/payrollMgmt.png";
import expenseTracking from "../assets/expenseTracking.png";
import invoiceBilling from "../assets/invoiceBilling.png";
import ultimateAcc from "../assets/ultimateAcc.png";
import unmatch from "../assets/unmatch.png";
import companyProf from "../assets/companyProf.png";
import inventDsh from "../assets/inventDsh.png";
import selfServeProtal from "../assets/selfServeProtal.png";
import cusAccs from "../assets/cusAccs.png";
import customStoreTheme from "../assets/customStoreTheme.png";
import cusCentric from "../assets/cusCentric.png";
import unifiedPOS from "../assets/unifiedPOS.png";
import realtimeMon from "../assets/realtimeMon.png";
import autInvoicing from "../assets/autInvoicing.png";
import secPayment from "../assets/secPayment.png";
import advSales from "../assets/advSales.png";
import roleSpec from "../assets/roleSpec.png";
import empAtt from "../assets/empAtt.png";
import cusBranding from "../assets/cusBranding.png";
import compExpense from "../assets/compExpense.png";
import supplierRelations from "../assets/supplierRelations.png";
import cusRel from "../assets/cusRel.png";
import autStock from "../assets/autStock.png";
import perMetrics from "../assets/perMetrics.png";
import dataBack from "../assets/dataBack.png";
import globalCurr from "../assets/globalCurr.png";
import taxCompliance from "../assets/taxCompliance.png";
import intBarcode from "../assets/intBarcode.png";
import empScheduling from "../assets/empScheduling.png";
import accurateCashAndCredit from "../assets/accurateCashAndCredit.png";
import multiiLang from "../assets/multiiLang.png";
import interactiveData from "../assets/interactiveData.png";
import promDisc from "../assets/promDisc.png";
import mobilesalesInv from "../assets/mobilesalesInv.png";
import intLoyalty from "../assets/intLoyalty.png";
import multiChannel from "../assets/multiChannel.png";
import shieldLoadinmg from "../assets/shieldLoadinmg.png";
import consUpdated from "../assets/consUpdated.png";
import hassleTOols from "../assets/hassleTOols.png";
import b2bPrev from "../assets/b2bPrev.png";
import { Link } from "react-router-dom";
import FrequentlyAskedQuestionsComponent from "./FrequentlyAskedQuestionsComponent";
import CTAButtons from "../components/CTAButtons";

function Accounting() {
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;
  const [activesection3Nav, setactivesection3Nav] = useState(
    t("Invoicing and Billing")
  );

  const displayComp = () => {
    if (!seeMore) {
      return comprehensiveList.slice(0, 8);
    }
    return comprehensiveList;
  };


  const askedQUestions = [
    {
      question: "What is B2B on DizLog?",
      answer:
        "B2B on DizLog refers to the business-to-business aspect of the DizLog platform. It means that DizLog offers specialized features and tools tailored specifically for businesses to conduct transactions, share information, and collaborate with other businesses, rather than serving individual consumers. This B2B focus enables seamless interactions between suppliers, manufacturers, distributors, and other entities within the supply chain, streamlining processes and enhancing efficiency in a business-to-business environment.",
      active: false,
    },
    {
      question: "How does it work?",
      answer: `DizLogPlus is a B2B product that likely functions as a comprehensive business management platform, designed to streamline various aspects of business operations. Here's a general overview of how it might work:
  
      <br/>     <br/>
      <strong>User Interface</strong>
      <br/>
      DizLogPlus likely offers a user-friendly interface that allows businesses to access and navigate through its various features effortlessly.
     
      <br/>     <br/>
      <strong>Customization</strong>
      <br/>
      Businesses can tailor the platform to their unique needs, setting up preferences and configurations to match their specific requirements.
     
      <br/>     <br/>
      <strong>POS and Inventory Management</strong>
      <br/>
      DizLogPlus would likely include advanced Point of Sale (POS) capabilities, enabling businesses to process sales transactions efficiently. It should also incorporate inventory management tools to track stock levels, manage stockouts, and automate reordering.
     
      <br/>     <br/>
      <strong>Order Fulfillment</strong>
      <br/>
      The platform might support order processing and fulfillment, making it easier for businesses to handle customer orders, track shipments, and manage returns.
      CRM and Customer Management: DizLogPlus may offer customer relationship
     
      <br/>     <br/>
      <strong>Real-time Analytics</strong>
      <br/>
      The product is likely to provide sophisticated reporting and analytics tools that give businesses real-time insights into their sales, inventory, and performance. This enables data-driven decision-making and helps identify growth opportunities.
     
      <br/>     <br/>
      <strong>Multi-Location Support</strong>
      <br/>
      If applicable, DizLogPlus might offer centralized management capabilities for businesses with multiple locations, allowing them to oversee operations and consolidate data from various sites.
     
     
      <br/>     <br/>
      <strong>Integration and Scalability</strong>
      <br/>
      DizLogPlus should seamlessly integrate with other systems and third-party applications, enhancing its usefulness and adaptability to different business setups and sizes.
     
      <br/>     <br/>
      <strong>Data Security</strong>
      <br/>
      As a crucial aspect of any business platform, DizLogPlus would likely prioritize data security to protect sensitive business information and comply with industry standards and regulations.
     
      `,
      active: false,
    },
    {
      question: "What features does B2B ecommerce solution have?",
      answer:
        "DizLogPlus B2B e-commerce solution boasts a diverse range of powerful features tailored to meet the unique demands of business-to-business interactions. The platform offers comprehensive Point of Sale (POS) functionality, empowering businesses to process sales transactions seamlessly. With advanced inventory management tools, DizLogPlus facilitates efficient stock tracking and automated reordering to avoid stockouts. The platform supports streamlined order fulfillment processes, enabling businesses to handle customer orders and monitor shipments effortlessly. Moreover, the integrated Customer Relationship Management (CRM) features help companies maintain valuable customer data, implement loyalty programs, and enhance customer retention. Real-time analytics and reporting provide businesses with data-driven insights into sales performance, inventory levels, and growth opportunities. DizLogPlus also facilitates centralized management for multi-location businesses, consolidating operations and data from various sites. With a focus on security, the solution ensures that sensitive information is safeguarded, complying with industry standards and regulations. Additionally, DizLogPlus boasts seamless integration with other systems and third-party applications, promoting scalability and adaptability to businesses of all sizes.",
      acttive: false,
    },
    {
      question: "What other features are coming?",
      answer:
        "DizLogPlus is continuously evolving to meet the ever-changing needs of businesses. The development team is working diligently to introduce a range of exciting new features that will further enhance the platform's capabilities. Among the upcoming additions are advanced marketing and sales tools, enabling businesses to craft targeted campaigns and promotions to reach their desired audience effectively. Additionally, DizLogPlus is set to offer enhanced analytics and reporting functionalities, providing businesses with even deeper insights into their operations and market trends. Improved integrations with third-party applications are on the horizon, fostering seamless connections with other essential tools in the business ecosystem. The platform is also gearing up to introduce AI-powered automation, streamlining repetitive tasks and optimizing workflows for increased efficiency. As DizLogPlus embraces cutting-edge technologies and user feedback, users can look forward to a future-proof B2B e-commerce solution that empowers businesses to thrive in the dynamic digital landscape.",
      acttive: false,
    },
    {
      question: "Can I integrate my ERP with DizLogPlus?",
      answer:
        "Absolutely! DizLogPlus is designed with seamless integration capabilities, allowing you to integrate your existing ERP (Enterprise Resource Planning) system with the platform. This integration facilitates the smooth flow of data between the two systems, streamlining your business operations and enhancing efficiency. By connecting your ERP with DizLogPlus, you can centralize and synchronize essential data, such as inventory levels, sales orders, customer information, and financial data, enabling real-time updates and accurate insights. This integration empowers you to harness the power of both systems, optimizing workflows, and gaining a comprehensive view of your business processes. Whether you use an in-house ERP or a third-party solution, DizLogPlus provides the flexibility and compatibility to make integration a seamless and advantageous experience.",
      acttive: false,
    },
    {
      question: "How can DTC businesses use a B2B platform?",
      answer:
        "Direct-to-Consumer (DTC) businesses can leverage a B2B platform like DizLogPlus to expand their reach and foster stronger relationships with wholesalers, retailers, and distributors. By utilizing a B2B platform, DTC businesses can offer wholesale accounts, enabling them to sell their products in bulk to other businesses. The platform provides DTC businesses with the necessary tools to manage bulk orders, negotiate pricing, and set specific terms for their B2B customers. Additionally, DTC businesses can benefit from advanced inventory management features to ensure they can meet the demands of their B2B partners efficiently. With a B2B platform in place, DTC businesses can streamline the ordering process, maintain accurate records, and strengthen communication with their wholesale customers, ultimately fostering growth and success in the B2B market segment.",
      acttive: false,
    },
    {
      question: "Is B2B on DizLogPlus included in my plan?",
      answer:
        "To find out if B2B features are included in your plan, I recommend checking the official website of DizLogPlus or contacting their customer support. They will be able to provide you with accurate and up-to-date information regarding the features available in your plan and any additional options or upgrades that may be available to you.",
      acttive: false,
    },
    {
      question:
        "How do you choose the best B2B ecommerce solution for your business?",
      answer:
        "Selecting the best B2B e-commerce solution for your business demands a methodical approach. Begin by understanding your business's specific needs, including order volume, product types, and integration requirements with existing systems. Scalability is crucial, ensuring the platform can grow alongside your business. Look for customization options to tailor the solution to your unique processes. Seamless integration with ERP and CRM systems is vital to avoid data silos. Prioritize data security and compliance to safeguard sensitive information. A user-friendly interface and mobile accessibility enhance usability. Real-time analytics and reporting empower data-driven decisions. Evaluate customer support, training resources, and overall cost to ensure a successful implementation and ROI. Reading user reviews and seeking references can offer valuable insights into other businesses' experiences. Finally, take advantage of free trials or demos to explore the platform firsthand before making a final decision.",
      acttive: false,
    },
  ];

  const section3MainNav = [
    t("Invoicing and Billing"),
    t("Expense Tracking"),
    t("Payroll Management"),
    t("Ledger Management"),
    t("Financial Reporting"),
    t("Sales Tax Tracking"),
    t("Bank Reconciliation"),
  ];

  const [seeMore, setSeeMore] = useState(false);

  let comprehensiveList = [
    {
      title: t("Supplier Profile Management"),
      img: companyProf,
      desc: t(`Effectively manage supplier information and communications.`),
    },
    {
      title: t(`Customer Centric Database`),
      img: cusCentric,
      desc: t(
        `Maintain comprehensive customer profiles, interactions, and preferences.`
      ),
    },
    {
      title: t(`Unified Point of Sale (POS)`),
      img: unifiedPOS,
      desc: t(`Offer a seamless checkout experience across various channels.`),
    },
    {
      title: t(`Real-time Inventory Monitoring`),
      img: realtimeMon,
      desc: t(
        `Keep track of stock levels to prevent stockouts and optimize supply`
      ),
    },
    {
      title: t(`Automated Invoicing System`),
      img: autInvoicing,
      desc: t(
        `Streamline billing processes with automated invoice generation and delivery.`
      ),
    },
    {
      title: t(`Secure Payment Gateways`),
      img: secPayment,
      desc: t(`Ensure secure and convenient payment options for customers`),
    },
    {
      title: t(`Advanced Sales Analytics`),
      img: advSales,
      desc: t(
        `Gain insights into sales trends and customer behavior for data-driven decisions.`
      ),
    },
    {
      title: t(`Role-specific Access Controls`),
      img: roleSpec,
      desc: t(
        `Maintain data security by assigning role-based access to employees.`
      ),
    },
    {
      title: t(`Employee Attendance Tracking`),
      img: empAtt,
      desc: t(`Monitor employee attendance for accurate payroll processing.`),
    },
    {
      title: t(`Custom Branding for Invoices`),
      img: cusBranding,
      desc: t(
        `Present a professional image with customized invoice templates.`
      ),
    },
    {
      title: t(`Comprehensive Expense Management`),
      img: compExpense,
      desc: t(`Track and categorize expenses for better financial control.`),
    },
    {
      title: t(`Supplier Relations Management`),
      img: supplierRelations,
      desc: t(`Efficiently manage supplier interactions and negotiations.`),
    },
    {
      title: t(`Customer Relationship Management (CRM)`),
      img: cusRel,
      desc: t(`Build strong customer relationships through detailed tracking.`),
    },
    {
      title: t(`Automated Stock Reordering`),
      img: autStock,
      desc: t(
        `Receive alerts and automate restocking to maintain optimal inventory levels.`
      ),
    },
    {
      title: t(`Performance Metrics Dashboard`),
      img: perMetrics,
      desc: t(`Evaluate employee performance using measurable metrics.`),
    },
    {
      title: t(`Data Backup and Security`),
      img: dataBack,
      desc: t(
        `Protect critical business data with automated backup and robust security measures.`
      ),
    },
    {
      title: t(`Global Currency Support`),
      img: globalCurr,
      desc: t(`Handle multiple currencies for international transactions.`),
    },
    {
      title: t(`Tax Compliance Tools`),
      img: taxCompliance,
      desc: t(`Stay compliant with tax regulations and simplify reporting.`),
    },
    {
      title: t(`Integrated Barcode Scanning`),
      img: intBarcode,
      desc: t(`Speed up inventory management and sales processes.`),
    },
    {
      title: t(`Intuitive Employee Scheduling`),
      img: empScheduling,
      desc: t(`Efficiently create and manage staff schedules.`),
    },
    {
      title: t(`Accurate Cash and Credit Reconciliation`),
      img: accurateCashAndCredit,
      desc: t(`Ensure accurate financial records with easy reconciliation.`),
    },
    {
      title: t(`Multi-language Customer Support`),
      img: multiiLang,
      desc: t(`Serve diverse customer needs with multiple language support.`),
    },
    {
      title: t(`Promotions and Discounts Management`),
      img: promDisc,
      desc: t(`Attract and retain customers with effective promotions.`),
    },
    {
      title: t(`Interactive Data Visualizations`),
      img: interactiveData,
      desc: t(`Visualize data trends for better decision-making.`),
    },
    {
      title: t(`Mobile Sales and Inventory Access`),
      img: mobilesalesInv,
      desc: t(`Stay connected on the go with mobile access to data.`),
    },
    {
      title: t(`Integrated Loyalty Program`),
      img: intLoyalty,
      desc: t(
        `Reward and retain loyal customers with a built-in loyalty program.`
      ),
    },
    {
      title: t(`Multi-Channel Online Store Management`),
      img: multiChannel,
      desc: t(`Seamlessly manage sales across various online platforms.`),
    },
  ];

  const section3Contents = () => {
    if (activesection3Nav == t("Invoicing and Billing")) {
      return {
        image: invoiceBilling,
        contents: {
          bullets: [
            {
              title: t("Streamlined Billing Process"),
              desc: t(
                " Dizlog's invoicing and quotation feature simplifies your billing process, making it easier to generate invoices and quotations accurately and efficiently. Say goodbye to manual paperwork and time-consuming calculations."
              ),
            },
            {
              title: t("Improved Cash Flow"),
              desc: t(
                "Timely and accurate invoicing leads to improved cash flow for your business. With Dizlog, you can quickly generate invoices and track payment statuses, ensuring that you receive payments on time and minimizing delays in your revenue cycle."
              ),
            },
            {
              title: t("Enhanced Client Relationships"),
              desc: t(
                "Professional invoices and quotations build trust and credibility with your clients. By providing clear and transparent documentation, you foster strong client relationships and demonstrate professionalism and attention to detail."
              ),
            },
          ],
        },
      };
    }

    if (activesection3Nav == t("Expense Tracking")) {
      return {
        image: expenseTracking,
        contents: {
          bullets: [
            {
              title: t("Bill Payment Integration"),
              desc: t(
                "Dizlog seamlessly integrates with bill payment systems, automating the process and ensuring timely payments. Say goodbye to manual bill processing, late fees, and missed payments."
              ),
            },
            {
              title: t("Real-time Expense Monitoring"),
              desc: t(
                "Track your bills and expenses in real-time within Dizlog. Receive instant updates on payment status, due dates, and upcoming expenses, providing you with a clear overview of your financial commitments."
              ),
            },
            {
              title: t("Expense Categorization and Analysis"),
              desc: t(
                "Effortlessly categorize your expenses, including bills, to gain insights into spending patterns. Dizlog's intuitive system allows you to analyze where your money is going, helping you make informed decisions about budgeting and cost management."
              ),
            },
          ],
        },
      };
    }

    if (activesection3Nav == t("Payroll Management")) {
      return {
        image: payrollMgmt,
        contents: {
          bullets: [
            {
              title: t("Timely and Error-free Processing"),
              desc: t(
                "Dizlog streamlines the payroll process, ensuring that your team gets paid accurately and on time, reducing the likelihood of errors and enhancing overall payroll efficiency."
              ),
            },
            {
              title: t("Employee Self-Service"),
              desc: t(
                "Empower your employees with Dizlog's self-service features, allowing them to access their payroll information, payslips, reimbursements, and more, enhancing transparency and communication."
              ),
            },
            {
              title: t("Comprehensive Statutory Reporting"),
              desc: t(
                "Dizlog simplifies statutory reporting by generating comprehensive reports that align with regulatory requirements. Easily access and share these reports during audits, ensuring transparency and compliance."
              ),
            },
          ],
        },
      };
    }

    if (activesection3Nav == t("Ledger Management")) {
      return {
        image: ledgerMgmt,
        contents: {
          bullets: [
            {
              title: t("Real-Time Ledger Updates"),
              desc: t(
                "Dizlog provides real-time updates to your general ledger, ensuring that your financial records are always up-to-date and accurate."
              ),
            },
            {
              title: t("Transaction History"),
              desc: t(
                "Easily access a comprehensive transaction history within the ledger, allowing for detailed scrutiny and analysis of financial activities."
              ),
            },
            {
              title: t("Automated Posting"),
              desc: t(
                "Dizlog automates posting entries to the ledger, minimizing the risk of errors and ensuring consistency in your accounting records."
              ),
            },
          ],
        },
      };
    }

    if (activesection3Nav == t("Financial Reporting")) {
      return {
        image: financialRep,
        contents: {
          bullets: [
            {
              title: t("Profit and Loss Statements"),
              desc: t(
                "Generate detailed Profit and Loss statements effortlessly. Dizlog's reporting capabilities allow you to track revenue, expenses, and net profit over specific periods, providing a clear overview of your financial performance."
              ),
            },
            {
              title: t("Balance Sheets"),
              desc: t(
                "Easily create balance sheets to understand your business's financial position. Dizlog's reporting tools ensure accuracy in representing your assets, liabilities, and equity, facilitating a holistic view of your financial standing."
              ),
            },
            {
              title: t("Cash Flow Analysis"),
              desc: t(
                "Conduct thorough cash flow analysis with Dizlog's reporting features. Track the inflow and outflow of cash, identify trends, and make strategic decisions to optimize your liquidity."
              ),
            },
          ],
        },
      };
    }

    if (activesection3Nav == t("Sales Tax Tracking")) {
      return {
        image: salesTaxTrack,
        contents: {
          bullets: [
            {
              title: t("Effortless Sales Tax Management"),
              desc: t(
                "Simplify your sales tax tracking process with Dizlog Accounting. Our intuitive platform automates sales tax calculations, filings, and reporting, saving you time and ensuring compliance with tax regulations."
              ),
            },
            {
              title: t("Stay Compliant, Stress-Free"),
              desc: t(
                "With Dizlog Accounting, you can rest assured that you're always compliant. Our robust system keeps track of sales tax rates, rules, and deadlines, so you can focus on growing your business without worrying about tax penalties or audits."
              ),
            },
            {
              title: t("Tailored Tax Solutions for Your Business"),
              desc: t(
                "Our platform empowers you to create customized tax rules and exemptions tailored to your unique business needs. Dizlog Accounting offers the flexibility to design and implement manual tax rules, ensuring accurate and compliant sales tax management for your business."
              ),
            },
          ],
        },
      };
    }

    if (activesection3Nav == t("Bank Reconciliation")) {
      return {
        image: banRecon,
        contents: {
          bullets: [
            {
              title: t("Upload Bank Accounts and Bank Statements"),
              desc: t(
                "Simplify your reconciliation process by seamlessly uploading your bank accounts and statements with Dizlog. Our user-friendly interface allows you to effortlessly import your financial data, eliminating the need for manual entry."
              ),
            },
            {
              title: t("Sync with Existing Ledger Transactions"),
              desc: t(
                "Dizlog syncing feature ensures that each bank statement effortlessly matches with corresponding ledger transactions, eliminating the need for redundant data entry,"
              ),
            },
            {
              title: t("Automated Bank Rules"),
              desc: t(
                "Our customizable rules allow you to define specific criteria for matching transactions, saving you time and reducing the risk of errors. With Dizlog, you can set up rules based on transaction amounts, dates, and descriptions, ensuring that your records are always in sync."
              ),
            },
          ],
        },
      };
    }
  };

  return (
    <>
      <Header screen="get-help" />
      <section>
        <div className="min-h-[600px] bg-[#004AAD] flex flex-1 p-4">
          <div className="flex w-full min-h-[600px] flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row">
            <div className="flex flex-1 flex-col  justify-center items-center">
              <div className="flex flex-col">
                <span
                  className="font-[Roboto] font-[500] 
                        text-base leading-[18px] tracking-[0.38px] text-white "
                >
                  Accounting
                </span>
                <span
                  className="font-[Raleway] font-[700]  w-[591px]
                  hidden xl:block lg:block
                        text-[42px] leading-[48px] tracking-[0.2px] text-white py-2"
                >
                  Unleash the Power of Seamless Accounting with Dizlog
                </span>
                <span
                  className="font-[Raleway] font-[700] 
                  block xl:hidden lg:hidden
                        text-[20px] l tracking-[0.2px] text-white py-2"
                >
                  Unleash the Power of Seamless Accounting with Dizlog
                </span>
                <span
                  className="font-[Roboto] font-[400] 
                        text-sm leading-[18px] tracking-[0.47px] text-white mb-4"
                >
                  Managing your finances has never been this effortless
                </span>

                {/* <DownloadLinks /> */}
                <CTAButtons />
              </div>
            </div>
            <div className="flex flex-1 items-center justify-start">
              <img
                src={accountingBanner}
                className="h-auto xl:h-[376.61px] lg:h-[376.61px] w-[670.86px] "
              />
            </div>
          </div>
        </div>
      </section>

      <section id="section3">
        <div
          className="
        bg-white

          min-h-[656px] w-full flex flex-col items-center py-6"
        >
          <div className="flex flex-col w-full items-center pt-6">
            <span
              className="font-[Raleway] font-[700]
              text-lg sm:text-[42px] text-[#00215D]
              leading-[48px] tracking-[0.2px]
              text-center w-full xl:w-[50%] lg:w-[50%] md:w-[70%] sm:w-[70%]"
            >
              {t(
                "Transform Your Business with Dizlog's Advanced Accounting Solutions"
              )}
            </span>
            <span
              className="pt-3 font-[Roboto] font-[400] text-[15px] text-[#6B6B6B] 
              w-[90%] xl:w-[50%] lg:w-[50%] md:w-[70%] sm:w-[70%] text-center"
            >
              {t(
                "Elevate your business success with Dizlog's powerful accounting features. Our platform simplifies your financial management, putting you in control of every aspect"
              )}
            </span>
          </div>
          <div
            className="flex flex-col 
            w-[90%] xl:w-[80%] lg:w-[80%] md:w-[90%] sm:w-[90%] h-full"
          >
            <div>
              <div className="flex overflow-x-auto mt-4">
                {section3MainNav.map((item) => (
                  <div
                    onClick={() => setactivesection3Nav(item)}
                    className={`cursor-pointer flex flex-1 p-[0.50rem] items-center  justify-center ${
                      item == activesection3Nav
                        ? `bg-[#FEF7EC] border-b-4 border-b-orange-400 `
                        : "bg-white"
                    }`}
                  >
                    <span
                      className="text-[14px] font-[Roboto] font-[600] text-[#F09000] whitespace-no-wrap"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="flex flex-1 py-12
                flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-col"
            >
              <div
                className="flex flex-1 justify-center items-center 
              "
              >
                <img
                  src={section3Contents().image}
                  className="h-[305.25px] w-[540px]
                  "
                />
              </div>
              <div className="flex flex-1 justify-center flex-col mt-4">
                <span className="text-[#00215D] font-[Raleway] font-[600] text-sm">
                  {section3Contents().contents.title}
                </span>
                <div className="w-[97%]">
                  {section3Contents().contents.bullets.map((item) => (
                    <div className="flex py-2 ">
                      <div className=" w-12 items-center flex justify-center">
                        <img
                          src={orangeCircleCheckWhite}
                          className="h-[20px] w-[20px]"
                        />
                      </div>
                      <div className="leading-[15px] flex flex-col w-full">
                        <span className=" text-[#00215D] text-[15px] font-[700] pb-1 font-[Roboto]">
                          {item.title}
                        </span>
                        <span className="text-[#6B6B6B] text-[13px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] font-[400] font-[Roboto] leading-[18px] tracking-[0.47px]">
                          {item.desc}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="xl:h-[430px] lg:h-[430px] bg-[#DEC6B0]">
          <div className="flex flex-1  h-full flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row">
            <div className="flex flex-1 justify-center items-end">
              <img src={ultimateAcc} className="h-[290px] xl:h-[400px] lg:h-[400px] md:h-[400px]  w-auto" />
            </div>
            <div className="flex flex-1 flex justify-center flex-col items-start p-4">
              <span className="font-[Roboto] font-[500] text-sm xl:text-[16px] md:text-base lg:text-base sm:text-base text-[#00215D] leading-[18px] tracking-[0.38px]">
                Accounting on DizLog
              </span>
              <span className="hidden xl:block lg:block font-[Raleway] font-[700] w-[480px] text-[42px] text-[#00215D] leading-[48px] tracking-[0.2px]">
                Your Ultimate Accounting Solution
              </span>

              <span className="block xl:hidden lg:hidden md:hidden sm:hidden 
              font-[Raleway] font-[700] text-[20px] text-[#00215D]  tracking-[0.2px] ">
                      {t("Your long-awaited B2B E-commerce Platform has Arrived")}
                  </span>

              <span className="block lg:w-[490px] font-[Roboto] mt-6 font-[400] text-[14px] text-[#6B6B6B] leading-[18px] tracking-[0.47px]">
                Step into the future of accounting integration with DizLog and
                open doors to unparalleled growth and success. This robust
                platform seamlessly integrates all features—inventory
                management, point of sale (POS), customer management, invoicing,
                and online store—providing a comprehensive and efficient
                approach to managing your business's financial ecosystem. DizLog
                empowers you to effortlessly synchronize accounting processes
                for both wholesale clients and direct customers, ensuring a
                streamlined and cohesive experience. Elevate your business to
                new heights with DizLog, where accounting integration meets
                unprecedented efficiency.
              </span>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div
          className="min-h-[430px] bg-[#FEEDD3] flex
          flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row"
        >
          <div className="flex flex-1  justify-center items-center py-4">
            <div className="w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
              <span
                className="text-[#00215D] mt-2 text-[16px] font-[500] font-[Roboto]
                            leading-[18px]"
              >
                {t("All-in-one platform")}
              </span>
              <span
                className=" w-[523px] hidden xl:block lg:block md:block sm:block text-[#00215D] mt-2 text-[20px] xl:text-[39px] lg:text-[39px] md:text-[39px] sm:text-[39px] font-[700] font-[Raleway]
                            leading-[18px] xl:leading-[45.79px] lg:leading-[45.79px] md:leading-[45.79px] sm:leading-[45.79px] tracking-[0.2px]"
              >
                {t("Powerful Features for Your Business Success")}
              </span>
              <span
                className=" w-auto block my-2xl:hidden lg:hidden md:hidden sm:hidden text-[#00215D] mt-2 text-[20px] xl:text-[39px] lg:text-[39px] md:text-[39px] sm:text-[39px] font-[700] font-[Raleway]
                            leading-[18px] xl:leading-[45.79px] lg:leading-[45.79px] md:leading-[45.79px] sm:leading-[45.79px] tracking-[0.2px]"
              >
                {t("Powerful Features for Your Business Success")}
              </span>
              <span
                className="text-[#6B6B6B] w-full xl:w-[457px] lg:w-[457px] md:w-[457px] sm:w-[457px] mt-2 text-[14px] font-[400] font-[Roboto]
                            leading-[18px] tracking-[0.47px]"
              >
                {t(
                  "Dizlog is equipped with an extensive set of features that stand tall among industry leaders. From seamless invoicing and expense tracking to efficient payroll management and insightful financial reporting, we've got your business needs covered. Our platform is designed to elevate your financial management experience and empower your business for success."
                )}
              </span>
            </div>
          </div>
          <div className="flex flex-1 items-end justify-center">
            <img
              src={unmatch}
              className="h-[200px] xl:h-[380px] lg:h-[380px] md:h-[380px] sm:h-[380px] w-auto"
            />
          </div>
        </div>
      </section>

      <section>
        <div className="py-6 min-h-[848px] bg-[#F6F6FF]">
          <div className="flex flex-col items-center py-12">
            <span
              className="w-[90%] xl:w-[800px] lg:w-[800px] md:w-[80%] sm:w-[80%] font-[Raleway] text-center font-[700] text-[#00215D] 
              leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px] text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px]
              "
            >
              {t(
                "Features that seamlessly integrate with your business's accounting needs."
              )}
            </span>
            <span className="font-[Roboto] mt-6 w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] text-center font-[400] text-[#6B6B6B] leading-[18px] text-[14px]">
              {t(
                "Effortlessly manage your business finances with Dizlog's integrated accounting features. Tailored to suit your needs, our platform ensures a seamless and efficient financial management experience."
              )}
            </span>
          </div>
          <div className="flex w-full justify-center">
            <div
              className="px-2
              w-full xl:w-[67%] lg:w-[85%] md:w-[98%] sm:w-full
              "
            >
              <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-3 grid-cols-1 gap-8 xl:gap-2 lg:gap-2 md:gap-2 sm:gap-2">
                {displayComp().map((item) => (
                  <div className="mb-2 p-3 shadow-md h-[176px] bg-white rounded-md relative w-full flex flex-col mb-4">
                    <img
                      src={item.img}
                      className="h-[62px] w-[62px] relative "
                      style={{
                        left: "35%",
                        top: "-55px",
                      }}
                    />
                    <div className="flex flex-col absolute mt-4 pr-3">
                      <div className="flex flex-col items-center">
                        <span className="font-[Raleway] font-[700] text-center text-[16px] text-[#00215D]">
                          {item.title}
                        </span>
                      </div>
                      <div className="flex items-center mt-2">
                        <span className="font-[Roboto] text-center font-[400] text-[13px] text-[#6B6B6B] leading-[16px] items-center">
                          {item.desc}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className="flex w-full justify-center cursor-pointer"
            onClick={() => setSeeMore(!seeMore)}
          >
            <span className="text-[#F09000] font-[Roboto] font-[600] text-[15px]">
              {t("See")} {seeMore ? t("Less") : t("More")}
            </span>
          </div>
        </div>
      </section>

      <section className="flex flex-row justify-center items-center min-h-[290px] bg-[#FEEDD3] ">
        <div
          className="flex flex-col md:flex-row py-4 items-center p-4 max-w-4xl"
        >
          <div className="flex flex-1 items-center justify-center mx-2 mt-2 w-full md:w-1/2">
            <div className="w-full bg-white shadow-sm rounded-md lg:h-[200px] md:h-[300px] flex p-4 gap-2">
              <div className="w-12 sm:w-20 flex flex-col justify-center">
                <img
                  src={shieldLoadinmg}
                  className="w-[80%] h-auto"
                />
              </div>
              <div className="flex flex-col flex-1 justify-center gap-2">
                <span className="text-[#00215D] font-[Raleway] font-[700] text-[17px] ">
                  {t("Pay once, Lifetime Updates")}
                </span>
                <span className="text-[#6B6B6B] font-[Roboto] font-[400] text-[13px]">
                  {t("With our commitment to innovation, a one-time payment ensures you'll benefit from lifelong updates, continuously enhancing your business journey. Join us today and forever experience the power of seamless B2B interactions.")}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-1 items-center justify-center mx-2 mt-2 w-full md:w-1/2">
            <div className="w-full bg-white shadow-sm rounded-md lg:h-[200px] md:h-[300px] flex p-4 gap-2">
              <div className="w-12 sm:w-20 flex flex-col justify-center">
                <img
                  src={consUpdated}
                  className="w-[80%] h-auto"
                />
              </div>
              <div className="flex flex-col flex-1 justify-center gap-2">
                <span className="text-[#00215D] font-[Raleway] font-[700] text-[17px] ">
                  {t("Constantly Updated and Supported")}
                </span>
                <span className="text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] ">
                  {t("Our development team strives to enhance the product’s performance while prioritizing the integration of new features tailored to the unique needs of each user. Our dedicated support team remains vigilant, ensuring prompt resolution of all user inquiries and requests.")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
        <section className="">
          <div
            className="bg-[#DEC6B0] min-h-[490px] flex p-4 pb-0
            flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row"
          >
            <div className="flex flex-1 items-center justify-center  py-6">
              <div className="w-[98%] xl:w-[60%] lg:w-[60%] md:w-[60%] sm:w-[60%] flex flex-col">
                <span
                  className="text-[#00215D] mt-2 text-[16px] font-[500] font-[Roboto]
                            leading-[18px]"
                >
                  {t("Seamless Accounting integration")}
                </span>
                <span
                  className="text-[#00215D] mt-2 text-[20px] sm:text-[39px] font-[700] font-[Raleway] leading-[25.79px] sm:leading-[45.79px]"
                >
                  {t("Cloud-Based Accounting Convenience")}
                </span>
                <span
                  className="text-[#6B6B6B] mt-3 text-[14px] font-[400] font-[Roboto]
                            leading-[20px]"
                >
                  {t("Access your financial data anytime, anywhere. With Dizlog's cloud-based platform, your accounting information is securely stored and readily available at your fingertips, ensuring you stay in control, whether you're in the office or on the go.")}
                </span>
                <div className="flex py-2 mt-3">
                  <div className=" w-6 items-center flex justify-start">
                    <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                  </div>
                  <div className="leading-[18px] w-full xl:w-[95%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
                    <span
                      className={` text-[14px] font-[400] font-[Roboto] text-[#6B6B6B]`}
                    >
                      {t("Unlock the flexibility to manage your accounting data from any location. Dizlog's cloud-based convenience ensures that your financial information is accessible wherever you are, providing the freedom to stay on top of your accounting tasks.")}
                    </span>
                  </div>
                </div>
                <div className="flex py-2 ">
                  <div className=" w-6 items-center flex justify-start">
                    <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                  </div>
                  <div className="leading-[18px] w-full xl:w-[95%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
                    <span
                      className={` text-[14px] font-[400] font-[Roboto] text-[#6B6B6B]`}
                    >
                      {t("Rest easy knowing that your accounting information is securely stored in the cloud. Dizlog employs robust security measures to safeguard your data, guaranteeing a reliable and protected repository for your financial records.")}
                    </span>
                  </div>
                </div>
                <div className="flex py-2 ">
                  <div className=" w-6 items-center flex justify-start">
                    <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                  </div>
                  <div className="leading-[18px] w-full xl:w-[95%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
                    <span
                      className={` text-[14px] font-[400] font-[Roboto] text-[#6B6B6B]`}
                    >
                      {t("Whether you're in the office or on the move, Dizlog's cloud-based platform gives you real-time control over your accounting processes. Experience the convenience of managing your financial data seamlessly, enhancing efficiency and decision-making.")}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-1 items-end justify-center">
              <img
                src={hassleTOols}
                className="h-[200px] xl:h-[526.65px] lg:h-[526.65px] md:h-[526.65px] sm:h-[526.65px] w-auto"
              />
            </div>
          </div>
        </section>
        <section>
          <div
            className="min-h-[430px] bg-[#F09000] flex
          flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row"
          >
            <div className="flex flex-1">
              <img src={b2bPrev} className="w-auto h-full" />
            </div>
            <div
              className="flex flex-1 flex-col justify-center
            items-center xl:items-start lg:items-start md:items-start sm:items-start py-4"
            >
              <span className="text-[#FFFFFF] font-[500] font-[Roboto] text-[17px] ">
                {t("Live Preview")}
              </span>
              <span className="w-[80%] xl:w-[513px] lg:w-[513px] text-[#FFFFFF] mt-2 leading-[48px] font-[700] font-[Raleway] text-[42px] ">
                {t("Ready to experience hassle-free accounting?")}
              </span>
              <span className="w-[80%] text-[#FFFFFF] mt-2 leading-[18px] font-[400] font-[Roboto] text-[16px] ">
                {t("Book a Demo with Dizlog Today – Your Business Deserves the Best in Accounting!")}
              </span>
              <Link to="/live-demo">
                <button
                  className="outline-none border border-white w-[200px] h-[46px] mt-4 font-[Roboto]
                font-[700]  text-[15px] text-white"
                >
                  {t("LIVE PREVIEW")}
                </button>
              </Link>
            </div>
          </div>
        </section>
        <section>
          <div className="min-h-[691px] bg-[#F6F6FF]">
            <FrequentlyAskedQuestionsComponent
              askedQUestions={askedQUestions}
            />
          </div>
        </section>
      <Footer footerImage={Footer_GetHelp} />
    </>
  );
}

export default Accounting;
